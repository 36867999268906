.sv-radio__decorator {
  border-radius: 100%;
}
.sv-radio__svg {
  border: 3px solid var(--border-color, $border-color);
  border-radius: 100%;
  fill: transparent;
}
.sv-radio--allowhover:hover {
  .sv-radio__svg {
    fill: var(--border-color, $border-color);
  }
}
.sv-radio--checked .sv-radio__svg {
  border-color: var(--radio-checked-color, $radio-checked-color);
  fill: var(--radio-checked-color, $radio-checked-color);
}
.sv-radio--disabled .sv-radio__svg {
  border-color: var(--disable-color, $disable-color);
}
.sv-radio--disabled.sv-radio--checked .sv-radio__svg {
  fill: var(--disable-color, $disable-color);
}
