.sv-imagepicker__item {
  border: none;
  padding: 0.24em;
}
.sv-imagepicker__item--inline {
  display: inline-block;
}
.sv-imagepicker__item--inline:not(:last-child) {
  margin-right: 4%;
}
.sv-imagepicker__image {
  border: 0.24em solid transparent;
  display: block;
  pointer-events: none;
}
.sv-imagepicker__label {
  cursor: inherit;
}
.sv-imagepicker__text {
  font-size: 1.14em;
  padding-left: 0.24em;
}
.sv-imagepicker__item--allowhover:hover {
  .sv-imagepicker__image {
    background-color: var(--main-hover-color, $main-hover-color);
    border-color: var(--main-hover-color, $main-hover-color);
  }
}

.sv-imagepicker__item:not(.sv-imagepicker__item--checked) .sv-imagepicker__control:focus ~ div .sv-imagepicker__image {
  background-color: var(--main-hover-color, $main-hover-color);
  border-color: var(--main-hover-color, $main-hover-color);
}

.sv-imagepicker__item--checked {
  .sv-imagepicker__image {
    background-color: var(--main-color, $main-color);
    border-color: var(--main-color, $main-color);
  }
}
.sv-imagepicker__item {
  cursor: pointer;
}
.sv-imagepicker__item--disabled {
  cursor: default;
}
.sv-imagepicker__item--disabled.sv-imagepicker__item--checked {
  .sv-imagepicker__image {
    background-color: var(--disable-color, $disable-color);
    border-color: var(--disable-color, $disable-color);
  }
}
