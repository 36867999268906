.sv-item {
  position: relative;
  cursor: pointer;
}
.sv-item--disabled {
  cursor: default;
}
.sv-item__decorator {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border: solid 1px transparent;
  vertical-align: middle;
}
.sv-item__svg {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.sv-item__control:focus + .sv-item__decorator {
  border-color: var(--main-color, $main-color);
  outline: none;
}
.sv-item__control-label {
  position: relative;
  top: 4px;
}
