.sv-question .sv-selectbase {
  margin-bottom: 4px;
}

.sv-selectbase__item {
  margin-bottom: 0.425em;
  vertical-align: top;
}

.sv-selectbase__item--inline {
  display: inline-block;
  padding-right: 5%;
}

.sv-selectbase__column {
  min-width: 140px;
  vertical-align: top;
}

.sv-selectbase__label {
  position: relative;
  display: block;
  box-sizing: border-box;
  cursor: inherit;
  margin-left: 41px;
  min-height: 30px;
}

[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {
  .sv-selectbase__label {
    margin-right: 41px;
    margin-left: 0;
  }
}

.sv-selectbase__decorator.sv-item__decorator {
  position: absolute;
  left: -41px;
}

[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {
  .sv-selectbase__decorator.sv-item__decorator {
    left: initial;
    right: -41px;
  }
}

.sv-selectbase__clear-btn {
  margin-top: 0.9em;
  background-color: var(--clean-button-color, $clean-button-color);
}

.sv-selectbase .sv-selectbase__item.sv-q-col-1 {
  padding-right: 0;
}

.sv-question .sv-q-column-1 {
  width: 100%;
  max-width: 100%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
}

.sv-question .sv-q-column-2 {
  max-width: 50%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
}

.sv-question .sv-q-column-3 {
  max-width: 33.33333%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
}

.sv-question .sv-q-column-4 {
  max-width: 25%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
}

.sv-question .sv-q-column-5 {
  max-width: 20%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
}