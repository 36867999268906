/* latin-ext */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyCMIT5lu.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0ITw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  src: local("Raleway Bold"), local("Raleway-Bold"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwJYtWqhPAMif.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  src: local("Raleway Bold"), local("Raleway-Bold"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwJYtWqZPAA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0ISQ.woff) format("woff");
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  src: local("Raleway Bold"), local("Raleway-Bold"), url(https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwJYtWqZPBg.woff) format("woff");
}
:root {
  --sjs-default-font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sv-dragdrop-movedown {
  transform: translate(0, 0);
  animation: svdragdropmovedown 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmovedown {
  0% {
    transform: translate(0, -50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.sv-dragdrop-moveup {
  transform: translate(0, 0);
  animation: svdragdropmoveup 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmoveup {
  0% {
    transform: translate(0, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
:root {
  --sjs-transition-duration: 150ms;
}

sv-popup {
  display: block;
  position: absolute;
}

.sv-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  outline: none;
  z-index: 2000;
  height: 100vh;
}

.sv-dropdown-popup {
  height: 0;
}

.sv-popup__container {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1));
  border-radius: var(--sjs-corner-radius, 4px);
  position: absolute;
  padding: 0;
}

.sv-popup__shadow {
  width: 100%;
  height: 100%;
  border-radius: var(--sjs-corner-radius, 4px);
}

.sv-popup__body-content {
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  border-radius: var(--sjs-corner-radius, 4px);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 100vw;
}

.sv-popup--modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-semitransparent, rgba(144, 144, 144, 0.5));
  padding: calc(11 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(15 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
}

.sv-popup--modal > .sv-popup__container {
  position: static;
  display: flex;
}

.sv-popup--modal > .sv-popup__container > .sv-popup__shadow > .sv-popup__body-content {
  box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1));
  background-color: var(--sjs-general-backcolor-dim-light, var(--background-dim-light, #f9f9f9));
  padding: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: auto;
}
.sv-popup--modal > .sv-popup__container > .sv-popup__shadow > .sv-popup__body-content .sv-popup__body-footer {
  padding-bottom: 2px;
}

.sv-popup--confirm-delete .sv-popup__shadow {
  height: initial;
}
.sv-popup--confirm-delete .sv-popup__container {
  border-radius: var(--sjs-base-unit, var(--base-unit, 8px));
}
.sv-popup--confirm-delete .sv-popup__body-content {
  border-radius: var(--sjs-base-unit, var(--base-unit, 8px));
  max-width: min-content;
  align-items: flex-end;
  min-width: 452px;
}
.sv-popup--confirm-delete .sv-popup__body-header {
  color: var(--sjs-font-editorfont-color, var(--sjs-general-forecolor, rgba(0, 0, 0, 0.91)));
  margin-bottom: 0;
  align-self: self-start;
  /* UI/Default */
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-size: var(--sjs-font-size, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  /* 150% */
}
.sv-popup--confirm-delete .sv-popup__scrolling-content {
  display: none;
}
.sv-popup--confirm-delete .sv-popup__body-footer {
  padding-bottom: 0;
  max-width: max-content;
}
.sv-popup--confirm-delete .sv-popup__body-footer .sv-action-bar {
  gap: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-popup--overlay {
  width: 100%;
  height: var(--sv-popup-overlay-height, 100vh);
}
.sv-popup--overlay .sv-popup__container {
  background: var(--background-semitransparent, rgba(144, 144, 144, 0.5));
  max-width: 100vw;
  max-height: calc(var(--sv-popup-overlay-height, 100vh) - 1 * var(--sjs-base-unit, var(--base-unit, 8px)));
  height: calc(var(--sv-popup-overlay-height, 100vh) - 1 * var(--sjs-base-unit, var(--base-unit, 8px)));
  width: 100%;
  padding-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border: unset;
}
.sv-popup--overlay .sv-popup__body-content {
  max-height: var(--sv-popup-overlay-height, 100vh);
  max-width: 100vw;
  border-radius: calc(4 * (var(--sjs-corner-radius, 4px))) calc(4 * (var(--sjs-corner-radius, 4px))) 0px 0px;
  background: var(--sjs-general-backcolor, var(--background, #fff));
  box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1));
  padding: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(100% - 1 * var(--sjs-base-unit, var(--base-unit, 8px)));
}
.sv-popup--overlay .sv-popup__scrolling-content {
  height: calc(100% - 10 * var(--base-unit, 8px));
}
.sv-popup--overlay .sv-popup__body-footer {
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-popup--overlay .sv-popup__body-footer .sv-action-bar {
  width: 100%;
}
.sv-popup--overlay .sv-popup__body-footer-item {
  width: 100%;
}
.sv-popup--overlay .sv-popup__button.sv-popup__button {
  background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  border: 2px solid var(--sjs-primary-backcolor, var(--primary, #19b394));
  color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
}

.sv-popup--overlay .sv-popup__body-footer .sv-action {
  flex: 1 0 0;
}

.sv-popup--modal .sv-popup__scrolling-content {
  padding: 2px;
  margin: -2px;
}

.sv-popup__scrolling-content {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.sv-popup__scrolling-content::-webkit-scrollbar,
.sv-popup__scrolling-content *::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}
.sv-popup__scrolling-content::-webkit-scrollbar-thumb,
.sv-popup__scrolling-content *::-webkit-scrollbar-thumb {
  background: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
}

.sv-popup__content {
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.sv-popup--show-pointer.sv-popup--top .sv-popup__pointer {
  transform: translate(calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))))) rotate(180deg);
}

.sv-popup--show-pointer.sv-popup--bottom .sv-popup__pointer {
  transform: translate(calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px)))), calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
}

.sv-popup--show-pointer.sv-popup--right .sv-popup__container {
  transform: translate(var(--sjs-base-unit, var(--base-unit, 8px)));
}
.sv-popup--show-pointer.sv-popup--right .sv-popup__container .sv-popup__pointer {
  transform: translate(-12px, -4px) rotate(-90deg);
}

.sv-popup--show-pointer.sv-popup--left .sv-popup__container {
  transform: translate(calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
}
.sv-popup--show-pointer.sv-popup--left .sv-popup__container .sv-popup__pointer {
  transform: translate(-4px, -4px) rotate(90deg);
}

.sv-popup__pointer {
  display: block;
  position: absolute;
}
.sv-popup__pointer:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-left: var(--sjs-base-unit, var(--base-unit, 8px)) solid transparent;
  border-right: var(--sjs-base-unit, var(--base-unit, 8px)) solid transparent;
  border-bottom: var(--sjs-base-unit, var(--base-unit, 8px)) solid var(--sjs-general-backcolor, var(--background, #fff));
  align-self: center;
}

.sv-popup__body-header {
  font-family: Open Sans;
  font-size: calc(1.5 * (var(--sjs-font-size, 16px)));
  line-height: calc(2 * (var(--sjs-font-size, 16px)));
  font-style: normal;
  font-weight: 700;
  margin-bottom: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
}

.sv-popup__body-footer {
  display: flex;
  margin-top: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-popup__body-footer .sv-action-bar {
  gap: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-popup__button {
  margin: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-popup--modal .sv-list__filter,
.sv-popup--overlay .sv-list__filter {
  padding-top: var(--sjs-base-unit, var(--base-unit, 8px));
}
.sv-popup--modal .sv-list__filter-icon,
.sv-popup--overlay .sv-list__filter-icon {
  top: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-popup--dropdown .sv-list__filter {
  margin-bottom: var(--sjs-base-unit, var(--base-unit, 8px));
}
.sv-popup--dropdown .sv-popup__shadow {
  box-shadow: var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1));
}
.sv-popup--dropdown .sv-popup__body-content {
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) 0;
  height: 100%;
}

.sv-popup--dropdown > .sv-popup__container > .sv-popup__shadow > .sv-popup__body-content .sv-list {
  background-color: transparent;
}

.sv-dropdown-popup .sv-popup__body-content {
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
}
.sv-dropdown-popup .sv-list__filter {
  margin-bottom: 0;
}

.sv-popup--overlay .sv-popup__body-content {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.sv-popup--dropdown-overlay {
  z-index: 2001;
  padding: 0;
}
.sv-popup--dropdown-overlay .sv-popup__body-content {
  padding: 0;
  border-radius: 0;
}
.sv-popup--dropdown-overlay .sv-popup__body-footer .sv-action-bar .sv-action {
  flex: 0 0 auto;
}
.sv-popup--dropdown-overlay .sv-popup__button.sv-popup__button {
  background-color: transparent;
  color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  border: none;
  box-shadow: none;
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin: 0;
}
.sv-popup--dropdown-overlay .sv-popup__container {
  max-height: calc(var(--sv-popup-overlay-height, 100vh));
  height: calc(var(--sv-popup-overlay-height, 100vh));
  padding-top: 0;
}
.sv-popup--dropdown-overlay .sv-popup__body-content {
  height: calc(var(--sv-popup-overlay-height, 100vh));
}
.sv-popup--dropdown-overlay .sv-popup__body-footer {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  margin-top: 0;
  padding-top: var(--sjs-base-unit, var(--base-unit, 8px));
  padding-bottom: var(--sjs-base-unit, var(--base-unit, 8px));
  border-top: 1px solid var(--sjs-border-light, var(--border-light, #eaeaea));
}
.sv-popup--dropdown-overlay .sv-popup__scrolling-content {
  height: calc(100% - 6 * var(--base-unit, 8px));
}
.sv-popup--dropdown-overlay .sv-list__filter-icon .sv-svg-icon {
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-popup--dropdown-overlay .sv-list__container {
  padding: 0;
}
.sv-popup--dropdown-overlay .sv-list {
  flex-grow: 1;
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) 0;
}
.sv-popup--dropdown-overlay .sv-list__filter {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-base-unit, var(--base-unit, 8px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-popup--dropdown-overlay .sv-list__filter-icon {
  position: static;
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-popup--dropdown-overlay .sv-list__empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
}
.sv-popup--dropdown-overlay .sv-popup__button:disabled {
  pointer-events: none;
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  opacity: 0.25;
}
.sv-popup--dropdown-overlay .sv-list__filter-clear-button {
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  appearance: none;
  border: none;
  border-radius: 100%;
  background-color: transparent;
}
.sv-popup--dropdown-overlay .sv-list__filter-clear-button svg {
  height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-popup--dropdown-overlay .sv-list__filter-clear-button svg use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}
.sv-popup--dropdown-overlay .sv-list__input {
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  font-size: max(16px, var(--sjs-font-size, 16px));
  line-height: max(24px, 1.5 * (var(--sjs-font-size, 16px)));
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-popup--dropdown-overlay .sv-list__item:hover .sv-list__item-body,
.sv-popup--dropdown-overlay .sv-list__item:focus .sv-list__item-body,
.sv-popup--dropdown-overlay .sv-list__item--focused .sv-list__item-body {
  background: var(--sjs-general-backcolor, var(--background, #fff));
}
.sv-popup--dropdown-overlay .sv-list__item:hover.sv-list__item--selected .sv-list__item-body,
.sv-popup--dropdown-overlay .sv-list__item:focus.sv-list__item--selected .sv-list__item-body,
.sv-popup--dropdown-overlay .sv-list__item--focused.sv-list__item--selected .sv-list__item-body {
  background: var(--sjs-primary-backcolor, var(--primary, #19b394));
  color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
  font-weight: 600;
}
.sv-popup--dropdown-overlay .sv-popup__body-footer .sv-action-bar {
  justify-content: flex-start;
}

.sv-dropdown-popup.sv-popup--dropdown-overlay .sv-list__filter {
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-base-unit, var(--base-unit, 8px)) calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-dropdown-popup.sv-popup--dropdown-overlay .sv-list {
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-dropdown-popup.sv-popup--dropdown-overlay .sv-popup__button.sv-popup__button {
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-dropdown-popup.sv-popup--dropdown-overlay .sv-popup__body-footer {
  padding-top: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-bottom: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: var(--sjs-general-backcolor-dim-light, var(--background-dim-light, #f9f9f9));
}
.sv-dropdown-popup.sv-popup--dropdown-overlay .sv-list__filter-icon .sv-svg-icon {
  width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-dropdown-popup.sv-popup--dropdown-overlay .sv-list__filter-icon {
  height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-dropdown-popup.sv-popup--dropdown-overlay .sv-list__input {
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-base-unit, var(--base-unit, 8px));
}

.sv-popup--dropdown-overlay.sv-multi-select-list .sv-list__item:hover.sv-list__item--selected .sv-list__item-body,
.sv-popup--dropdown-overlay.sv-multi-select-list .sv-list__item:focus.sv-list__item--selected .sv-list__item-body,
.sv-popup--dropdown-overlay.sv-multi-select-list .sv-list__item--focused.sv-list__item--selected .sv-list__item-body {
  background: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  font-weight: 400;
}

.sv-popup--dropdown-overlay.sv-popup--tablet .sv-popup__body-content {
  --sv-popup-overlay-max-height: calc(var(--sv-popup-overlay-height, 100vh) - var(--sjs-base-unit, var(--base-unit, 8px)) * 8);
  --sv-popup-overlay-max-width: calc(100% - var(--sjs-base-unit, var(--base-unit, 8px)) * 8);
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  max-height: var(--sv-popup-overlay-max-height);
  min-height: min(var(--sv-popup-overlay-max-height), 30 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: auto;
  width: auto;
  min-width: min(40 * (var(--sjs-base-unit, var(--base-unit, 8px))), var(--sv-popup-overlay-max-width));
  max-width: var(--sv-popup-overlay-max-width);
  border-radius: var(--sjs-corner-radius, 4px);
  overflow: hidden;
  margin: 0;
}
.sv-popup--dropdown-overlay.sv-popup--tablet .sv-popup__content,
.sv-popup--dropdown-overlay.sv-popup--tablet .sv-popup__scrolling-content,
.sv-popup--dropdown-overlay.sv-popup--tablet .sv-list__container {
  flex-grow: 1;
}

.sv-popup--visible {
  opacity: 1;
}

.sv-popup--hidden {
  opacity: 0;
}

.sv-popup--animate-enter {
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 0.15s;
}

.sv-popup--modal.sv-popup--animate-enter {
  animation-duration: 0.25s;
}

.sv-popup--animate-leave {
  animation-direction: reverse;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 0.15s;
}

.sv-popup--modal.sv-popup--animate-leave {
  animation-duration: 0.25s;
}

.sv-popup--hidden {
  opacity: 0;
}

@keyframes modalMoveDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(64px);
  }
}
@keyframes modalMoveUp {
  from {
    transform: translateY(64px);
  }
  to {
    transform: translateY(0);
  }
}
.sv-popup--modal.sv-popup--animate-leave .sv-popup__container {
  animation-name: modalMoveDown;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
}

.sv-popup--modal.sv-popup--animate-enter .sv-popup__container {
  animation-name: modalMoveUp;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
}

:root {
  --sjs-transition-duration: 150ms;
}

.sv_progress-buttons__container-center {
  text-align: center;
}

.sv_progress-buttons__container {
  display: inline-block;
  font-size: 0;
  width: 100%;
  max-width: 1100px;
  white-space: nowrap;
  overflow: hidden;
}

.sv_progress-buttons__image-button-left {
  display: inline-block;
  vertical-align: top;
  margin-top: 22px;
  font-size: calc(0.875 * (var(--sjs-font-size, 16px)));
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwb2x5Z29uIHBvaW50cz0iMTEsMTIgOSwxNCAzLDggOSwyIDExLDQgNyw4ICIvPg0KPC9zdmc+DQo=);
}

.sv_progress-buttons__image-button-right {
  display: inline-block;
  vertical-align: top;
  margin-top: 22px;
  font-size: calc(0.875 * (var(--sjs-font-size, 16px)));
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwb2x5Z29uIHBvaW50cz0iNSw0IDcsMiAxMyw4IDcsMTQgNSwxMiA5LDggIi8+DQo8L3N2Zz4NCg==);
}

.sv_progress-buttons__image-button--hidden {
  visibility: hidden;
}

.sv_progress-buttons__list-container {
  max-width: calc(100% - 36px);
  display: inline-block;
  overflow: hidden;
}

.sv_progress-buttons__list {
  display: inline-block;
  width: max-content;
  padding-left: 28px;
  padding-right: 28px;
  margin-top: 14px;
  margin-bottom: 14px;
}

.sv_progress-buttons__list li {
  width: 138px;
  font-size: calc(0.875 * (var(--sjs-font-size, 16px)));
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  position: relative;
  text-align: center;
  vertical-align: top;
  display: inline-block;
}

.sv_progress-buttons__list li:before {
  width: 24px;
  height: 24px;
  content: "";
  line-height: 30px;
  display: block;
  margin: 0 auto 10px auto;
  border: 3px solid;
  border-radius: 50%;
  box-sizing: content-box;
  cursor: pointer;
}

.sv_progress-buttons__list li:after {
  width: 73%;
  height: 3px;
  content: "";
  position: absolute;
  top: 15px;
  left: -36.5%;
}

.sv_progress-buttons__list li:first-child:after {
  content: none;
}

.sv_progress-buttons__list .sv_progress-buttons__page-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

.sv_progress-buttons__list .sv_progress-buttons__page-description {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sv_progress-buttons__list li.sv_progress-buttons__list-element--nonclickable:before {
  cursor: not-allowed;
}

:root {
  --sjs-transition-duration: 150ms;
}

.sv_progress-toc {
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  max-width: 336px;
  height: 100%;
  background: #FFFFFF;
  box-sizing: border-box;
  min-width: calc(32 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv_progress-toc .sv-list__item.sv-list__item--selected .sv-list__item-body {
  background: rgba(25, 179, 148, 0.1);
  color: #161616;
  font-weight: 400;
}
.sv_progress-toc .sv-list__item span {
  white-space: break-spaces;
}
.sv_progress-toc .sv-list__item-body {
  padding-inline-start: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-inline-end: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: var(--sjs-corner-radius, 4px);
  padding-top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-bottom: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv_progress-toc--left {
  border-right: 1px solid #D6D6D6;
}

.sv_progress-toc--right {
  border-left: 1px solid #D6D6D6;
}

.sv_progress-toc--mobile {
  position: fixed;
  top: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: auto;
  min-width: auto;
  height: auto;
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  z-index: 15;
  border-radius: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv_progress-toc--mobile > div {
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv_progress-toc--mobile:hover {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.sv-container-modern {
  color: var(--text-color, #404040);
  font-size: var(--font-size, var(--sjs-font-size, 16px));
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
}

.sv-container-modern__title {
  padding-left: 0.55em;
  color: var(--main-color, #1ab394);
  padding-top: 5em;
  padding-bottom: 0.9375em;
}
@media only screen and (min-width: 1000px) {
  .sv-container-modern__title {
    margin-right: 5%;
    margin-left: 5%;
  }
}
@media only screen and (max-width: 1000px) {
  .sv-container-modern__title {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.sv-container-modern__title h3 {
  margin: 0;
  font-size: 1.875em;
}

.sv-container-modern__title h5 {
  margin: 0;
}

.sv-container-modern__close {
  clear: right;
}

.sv-container-modern fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.sv-container-modern legend {
  border: none;
  padding: 0;
  margin: 0;
}

.sv-body {
  width: 100%;
  padding-bottom: calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-body__timer,
.sv-body__page,
.sv-body__footer.sv-footer.sv-action-bar {
  margin-top: 2em;
}
@media only screen and (min-width: 1000px) {
  .sv-body__timer,
  .sv-body__page,
  .sv-body__footer.sv-footer.sv-action-bar {
    margin-right: 5%;
    margin-left: 5%;
  }
}
@media only screen and (max-width: 1000px) {
  .sv-body__timer,
  .sv-body__page,
  .sv-body__footer.sv-footer.sv-action-bar {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.sv-body__timer {
  padding: 0 var(--sjs-base-unit, var(--base-unit, 8px));
  box-sizing: border-box;
}

.sv-body__progress {
  margin-bottom: 4.5em;
}

.sv-body__progress:not(:first-child) {
  margin-top: 2.5em;
}

.sv-root-modern {
  width: 100%;
  --sv-mobile-width: 600px;
}

.sv-page__title {
  margin: 0;
  margin-bottom: 1.333em;
  font-size: 1.875em;
  padding-left: 0.293em;
}

.sv-page__description {
  min-height: 2.8em;
  font-size: 1em;
  padding-left: 0.55em;
}

.sv-page__title + .sv-page__description {
  margin-top: -2.8em;
}

.sv-panel {
  box-sizing: border-box;
  width: 100%;
}

.sv-panel__title {
  font-size: 1.25em;
  margin: 0;
  padding: 0;
  padding-bottom: 0.1em;
  padding-left: 0.44em;
  padding-right: 0.44em;
  position: relative;
}

.sv-panel__footer {
  margin: 0;
  padding: 1em 0.44em 1em 0;
}

.sv-panel__description {
  padding-left: 0.55em;
}

.sv-panel__title--expandable {
  cursor: pointer;
  display: flex;
  padding-right: 24px;
  align-items: center;
}

.sv-panel__title--expandable:after {
  content: "";
  display: block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 10px 12px;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
}

.sv-panel__title--expandable.sv-panel__title--expanded:after {
  transform: rotate(180deg);
}

.sv-panel__icon {
  outline: none;
}

.sv-panel__icon:before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: 0.5em;
  width: 0.6em;
  margin-left: 1.5em;
  vertical-align: middle;
}

.sv-panel__icon--expanded:before {
  transform: rotate(180deg);
}

.sv-panel .sv-question__title {
  font-size: 1em;
  padding-left: 0.55em;
}

.sv-panel__content:not(:first-child) {
  margin-top: 0.75em;
}

.sv-panel .sv-row:not(:last-child) {
  padding-bottom: 1.875em;
}

.sv-panel__title--error {
  background-color: var(--error-background-color, rgba(213, 41, 1, 0.2));
}

.sv-paneldynamic__progress-container {
  position: relative;
  margin-left: 0.75em;
  margin-right: 250px;
  margin-top: 20px;
}

.sv-paneldynamic__add-btn {
  background-color: var(--add-button-color, #1948b3);
  float: right;
  margin-top: -18px;
}

[dir=rtl] .sv-paneldynamic__add-btn,
[style*="direction:rtl"] .sv-paneldynamic__add-btn,
[style*="direction: rtl"] .sv-paneldynamic__add-btn {
  float: left;
}

.sv-paneldynamic__add-btn--list-mode {
  float: none;
  margin-top: 1em;
}

.sv-paneldynamic__remove-btn {
  background-color: var(--remove-button-color, #ff1800);
  margin-top: 1.25em;
}

.sv-paneldynamic__remove-btn--right {
  margin-top: 0;
  margin-left: 1.25em;
}

.sv-paneldynamic__prev-btn,
.sv-paneldynamic__next-btn {
  box-sizing: border-box;
  display: inline-block;
  fill: var(--text-color, #404040);
  cursor: pointer;
  width: 0.7em;
  top: -0.28em;
  position: absolute;
}

.sv-paneldynamic__prev-btn svg,
.sv-paneldynamic__next-btn svg {
  display: block;
  height: 0.7em;
  width: 0.7em;
}

.sv-paneldynamic__prev-btn {
  left: -1.3em;
  transform: rotate(90deg);
}

.sv-paneldynamic__next-btn {
  right: -1.3em;
  transform: rotate(270deg);
}

.sv-paneldynamic__prev-btn--disabled,
.sv-paneldynamic__next-btn--disabled {
  fill: var(--disable-color, #dbdbdb);
  cursor: auto;
}

.sv-paneldynamic__progress-text {
  color: var(--progress-text-color, #9d9d9d);
  font-weight: bold;
  font-size: 0.87em;
  margin-top: 0.69em;
  margin-left: 1em;
}

.sv-paneldynamic__separator {
  border: none;
  margin: 0;
}

.sv-paneldynamic__progress--top {
  margin-bottom: 1em;
}

.sv-paneldynamic__progress--bottom {
  margin-top: 1em;
}

.sv-paneldynamic__panel-wrapper ~ .sv-paneldynamic__panel-wrapper {
  padding-top: 2.5em;
}

.sv-paneldynamic__panel-wrapper--in-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@supports (display: flex) {
  .sv-row {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .sv-row > .sv-row__panel,
  .sv-row__question:not(:last-child) {
    float: left;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 600px), only screen and (-ms-high-contrast: none) and (max-width: 600px) {
  .sv-row > .sv-row__panel,
  .sv-row__question:not(:last-child) {
    padding-bottom: 2.5em;
    float: none;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  [dir=rtl] .sv-row__question:not(:last-child),
  [style*="direction:rtl"] .sv-row__question:not(:last-child),
  [style*="direction: rtl"] .sv-row__question:not(:last-child) {
    float: right;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 6000px), only screen and (-ms-high-contrast: none) and (max-width: 6000px) {
  .sv-row__question--small:only-child {
    max-width: 3000px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 3000px), only screen and (-ms-high-contrast: none) and (max-width: 3000px) {
  .sv-row__question--small:only-child {
    max-width: 1200px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 2000px), only screen and (-ms-high-contrast: none) and (max-width: 2000px) {
  .sv-row__question--small:only-child {
    max-width: 700px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 1000px), only screen and (-ms-high-contrast: none) and (max-width: 1000px) {
  .sv-row__question--small:only-child {
    max-width: 500px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 500px), only screen and (-ms-high-contrast: none) and (max-width: 500px) {
  .sv-row__question--small:only-child {
    max-width: 300px;
  }
}
@media only screen and (-ms-high-contrast: active) and (max-width: 600px), only screen and (-ms-high-contrast: none) and (max-width: 600px) {
  .sv-row > .sv-row__panel,
  .sv-row__question {
    width: 100% !important;
    padding-right: 0 !important;
  }
}
.sv-row > .sv-row__panel,
.sv-row__question {
  vertical-align: top;
  white-space: normal;
}

.sv-row__question:first-child:last-child {
  flex: none !important;
}

.sv-row:not(:last-child) {
  padding-bottom: 2.5em;
}

.sv-question {
  overflow: auto;
  box-sizing: border-box;
  font-family: inherit;
  padding-left: var(--sv-element-add-padding-left, 0px);
  padding-right: var(--sv-element-add-padding-right, 0px);
}

.sv-question__title {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0.25em 0.44em;
  cursor: default;
  font-size: 1.25em;
}

.sv-question__required-text {
  line-height: 0.8em;
  font-size: 1.4em;
}

.sv-question__description {
  margin: 0;
  padding-left: 0.55em;
  font-size: 1em;
}

.sv-question__input {
  width: 100%;
  height: 1.81em;
}

.sv-question__content {
  margin-left: 0.55em;
}

.sv-question__erbox {
  color: var(--error-color, #d52901);
  font-size: 0.74em;
  font-weight: bold;
}

.sv-question__erbox--location--top {
  margin-bottom: 0.4375em;
}

.sv-question__erbox--location--bottom {
  margin-top: 0.4375em;
}

.sv-question__footer {
  padding: 0.87em 0;
}

.sv-question__title--answer {
  background-color: var(--answer-background-color, rgba(26, 179, 148, 0.2));
}

.sv-question__title--error {
  background-color: var(--error-background-color, rgba(213, 41, 1, 0.2));
}

.sv-question__header--location--top {
  margin-bottom: 0.65em;
}

.sv-question__header--location--left {
  float: left;
  width: 27%;
  margin-right: 0.875em;
}

[dir=rtl] .sv-question__header--location--left,
[style*="direction:rtl"] .sv-question__header--location--left,
[style*="direction: rtl"] .sv-question__header--location--left {
  float: right;
}

.sv-question__header--location--bottom {
  margin-top: 0.8em;
}

.sv-question__content--left {
  overflow: hidden;
}

.sv-question__other {
  margin-top: 0.5em;
}

.sv-question__form-group {
  margin-top: 0.5em;
}

.sv-question--disabled .sv-question__header {
  color: var(--disabled-text-color, rgba(64, 64, 64, 0.5));
}

.sv-image {
  display: inline-block;
}

.sv-question__title--expandable {
  cursor: pointer;
  display: flex;
  padding-right: 24px;
  align-items: center;
}

.sv-question__title--expandable:after {
  content: "";
  display: block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 10px 12px;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
}

.sv-question__title--expandable.sv-question__title--expanded:after {
  transform: rotate(180deg);
}

.sv-question__icon {
  outline: none;
}

.sv-question__icon:before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: 0.5em;
  width: 0.6em;
  margin-left: 1.5em;
  vertical-align: middle;
}

.sv-question__icon--expanded:before {
  transform: rotate(180deg);
}

.sv-progress {
  height: 0.19em;
  background-color: var(--header-background-color, #e7e7e7);
  position: relative;
}

.sv-progress__bar {
  position: relative;
  height: 100%;
  background-color: var(--main-color, #1ab394);
}

.sv-progress__text {
  position: absolute;
  margin-top: 0.69em;
  color: var(--progress-text-color, #9d9d9d);
  font-size: 0.87em;
  font-weight: bold;
  padding-left: 0.6321em;
}
@media only screen and (min-width: 1000px) {
  .sv-progress__text {
    margin-left: 5%;
  }
}
@media only screen and (max-width: 1000px) {
  .sv-progress__text {
    margin-left: 10px;
  }
}

.sv_progress-buttons__list li:before {
  border-color: var(--progress-buttons-color, #8dd9ca);
  background-color: var(--progress-buttons-color, #8dd9ca);
}

.sv_progress-buttons__list li:after {
  background-color: var(--text-border-color, #d4d4d4);
}

.sv_progress-buttons__list .sv_progress-buttons__page-title {
  color: var(--text-color, #404040);
}

.sv_progress-buttons__list .sv_progress-buttons__page-description {
  color: var(--text-color, #404040);
}

.sv_progress-buttons__list li.sv_progress-buttons__list-element--passed:before {
  border-color: var(--main-color, #1ab394);
  background-color: var(--main-color, #1ab394);
}

.sv_progress-buttons__list li.sv_progress-buttons__list-element--passed + li:after {
  background-color: var(--progress-buttons-color, #8dd9ca);
}

.sv_progress-buttons__list li.sv_progress-buttons__list-element--current:before {
  border-color: var(--main-color, #1ab394);
  background-color: white;
}

.sv_progress-buttons__list li.sv_progress-buttons__list-element--passed.sv_progress-buttons__list-element--current:before {
  border-color: var(--main-color, #1ab394);
  background-color: white;
}

.sv-title {
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.sv-description {
  color: var(--disabled-text-color, rgba(64, 64, 64, 0.5));
}

.sv-question .sv-selectbase {
  margin-bottom: 4px;
}

.sv-selectbase__item {
  margin-bottom: 0.425em;
  vertical-align: top;
}

.sv-selectbase__item--inline {
  display: inline-block;
  padding-right: 5%;
}

.sv-selectbase__column {
  min-width: 140px;
  vertical-align: top;
}

.sv-selectbase__label {
  position: relative;
  display: block;
  box-sizing: border-box;
  cursor: inherit;
  margin-left: 41px;
  min-height: 30px;
}

[dir=rtl] .sv-selectbase__label,
[style*="direction:rtl"] .sv-selectbase__label,
[style*="direction: rtl"] .sv-selectbase__label {
  margin-right: 41px;
  margin-left: 0;
}

.sv-selectbase__decorator.sv-item__decorator {
  position: absolute;
  left: -41px;
}

[dir=rtl] .sv-selectbase__decorator.sv-item__decorator,
[style*="direction:rtl"] .sv-selectbase__decorator.sv-item__decorator,
[style*="direction: rtl"] .sv-selectbase__decorator.sv-item__decorator {
  left: initial;
  right: -41px;
}

.sv-selectbase__clear-btn {
  margin-top: 0.9em;
  background-color: var(--clean-button-color, #1948b3);
}

.sv-selectbase .sv-selectbase__item.sv-q-col-1 {
  padding-right: 0;
}

.sv-question .sv-q-column-1 {
  width: 100%;
  max-width: 100%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
}

.sv-question .sv-q-column-2 {
  max-width: 50%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
}

.sv-question .sv-q-column-3 {
  max-width: 33.33333%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
}

.sv-question .sv-q-column-4 {
  max-width: 25%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
}

.sv-question .sv-q-column-5 {
  max-width: 20%;
  display: inline-block;
  padding-right: 1em;
  box-sizing: border-box;
}

.sv-multipletext {
  width: 100%;
  table-layout: fixed;
}

.sv-multipletext__item-label {
  display: flex;
  align-items: center;
}

.sv-multipletext__item {
  flex: 1;
}

.sv-multipletext__item-title {
  margin-right: 1em;
  width: 33%;
}

.sv-multipletext__cell:not(:first-child) {
  padding-left: 0.5em;
}

.sv-multipletext__cell:not(:last-child) {
  padding-right: 0.5em;
}

.sv-matrix {
  overflow-x: auto;
}
.sv-matrix .sv-table__cell--header {
  text-align: center;
}

.sv-matrix__label {
  display: inline-block;
  margin: 0;
}

.sv-matrix__cell {
  min-width: 10em;
  text-align: center;
}
.sv-matrix__cell:first-child {
  text-align: left;
}

.sv-matrix__text {
  cursor: pointer;
}

.sv-matrix__text--checked {
  color: var(--body-background-color, white);
  background-color: var(--main-color, #1ab394);
}

.sv-matrix__text--disabled {
  cursor: default;
}
.sv-matrix__text--disabled.sv-matrix__text--checked {
  background-color: var(--disable-color, #dbdbdb);
}

.sv-matrix__row--error {
  background-color: var(--error-background-color, rgba(213, 41, 1, 0.2));
}

.sv-matrixdynamic__add-btn {
  background-color: var(--add-button-color, #1948b3);
}

.sv-matrixdynamic__remove-btn {
  background-color: var(--remove-button-color, #ff1800);
}

.sv-detail-panel__icon {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 13px;
  width: 24px;
  transform: translate(-50%, -50%) rotate(270deg);
}

.sv-detail-panel__icon--expanded {
  transform: translate(-50%, -50%);
}

.sv-detail-panel__icon:before {
  content: "";
  display: block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%239A9A9A;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='19,6 17,4 10,11 3,4 1,6 10,15 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: 18px;
  width: 24px;
}

.sv-root-modern ::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: var(--main-hover-color, #9f9f9f);
}

.sv-root-modern ::-webkit-scrollbar-thumb {
  background: var(--main-color, #1ab394);
}

.sv-table {
  width: 100%;
  background-color: rgba(var(--main-hover-color, #9f9f9f), 0.1);
  border-collapse: separate;
  border-spacing: 0;
}
.sv-table tbody tr:last-child .sv-table__cell {
  padding-bottom: 2.5em;
}
.sv-table tr:first-child .sv-table__cell {
  padding-top: 1.875em;
}
.sv-table td:first-child,
.sv-table th:first-child {
  padding-left: 1.875em;
}
.sv-table td:last-child,
.sv-table th:last-child {
  padding-right: 1.875em;
}

.sv-table__row--detail {
  background-color: var(--header-background-color, #e7e7e7);
}
.sv-table__row--detail td {
  border-top: 1px solid var(--text-border-color, #d4d4d4);
  border-bottom: 1px solid var(--text-border-color, #d4d4d4);
  padding: 1em 0;
}

.sv-table__cell {
  padding: 0.9375em 0;
  box-sizing: content-box;
  vertical-align: top;
}

.sv-table__cell:not(:last-child) {
  padding-right: 1em;
}

.sv-table__cell:not(:first-child) {
  padding-left: 1em;
}

.sv-table__cell--header {
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-weight: bold;
  text-align: left;
}

.sv-table__cell--rowText {
  vertical-align: middle;
}

.sv-table__cell--detail {
  text-align: center;
  vertical-align: middle;
  width: 32px;
}

.sv-table__cell--detail-rowtext {
  vertical-align: middle;
}

.sv-table__cell--detail-panel {
  padding-left: 1em;
}

.sv-table__cell--detail-button {
  appearance: none;
  position: relative;
  border: 3px solid var(--border-color, rgba(64, 64, 64, 0.5));
  border-radius: 50px;
  text-align: center;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  background: transparent;
}

.sv-table__empty--rows--section {
  text-align: center;
  vertical-align: middle;
}

.sv-table__empty--rows--text {
  padding: 20px;
}

.sv-table__cell--actions sv-action-bar,
.sv-table__cell--actions .sv-action-bar {
  margin-left: 0;
  padding-left: 0;
}

.sv-footer.sv-action-bar {
  display: block;
  min-height: var(--base-line-height, 2em);
  padding: 2.5em 0 0.87em 0;
  margin-left: auto;
}
.sv-footer.sv-action-bar .sv-action__content {
  display: block;
}
.sv-footer.sv-action-bar .sv-action:not(:last-child) .sv-action__content {
  padding-right: 0;
}

.sv-btn--navigation {
  margin: 0 1em;
  float: right;
  background-color: var(--main-color, #1ab394);
}

.sv-footer__complete-btn,
.sv-footer__next-btn,
.sv-footer__preview-btn {
  float: right;
}

.sv-footer__prev-btn,
.sv-footer__edit-btn {
  float: left;
}

[dir=rtl] .sv-footer__complete-btn,
[style*="direction:rtl"] .sv-footer__complete-btn,
[style*="direction: rtl"] .sv-footer__complete-btn {
  float: left;
}
[dir=rtl] .sv-footer__preview-btn,
[style*="direction:rtl"] .sv-footer__preview-btn,
[style*="direction: rtl"] .sv-footer__preview-btn {
  float: left;
}
[dir=rtl] .sv-footer__next-btn,
[style*="direction:rtl"] .sv-footer__next-btn,
[style*="direction: rtl"] .sv-footer__next-btn {
  float: left;
}
[dir=rtl] .sv-footer__prev-btn,
[style*="direction:rtl"] .sv-footer__prev-btn,
[style*="direction: rtl"] .sv-footer__prev-btn {
  float: right;
}
[dir=rtl] .sv-footer__edit-btn,
[style*="direction:rtl"] .sv-footer__edit-btn,
[style*="direction: rtl"] .sv-footer__edit-btn {
  float: right;
}

.sv-btn.sv-action-bar-item,
.sv-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 1.214em;
  color: var(--body-background-color, white);
  cursor: pointer;
  font-family: inherit;
  font-size: 0.875em;
  font-weight: bold;
  outline: none;
  padding: 0.5em 2.786em 0.6em;
  text-align: left;
}

.sv-btn--navigation {
  background-color: var(--main-color, #1ab394);
}

.sv-item {
  position: relative;
  cursor: pointer;
}

.sv-item--disabled {
  cursor: default;
}

.sv-item__decorator {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border: solid 1px transparent;
  vertical-align: middle;
}

.sv-item__svg {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.sv-item__control:focus + .sv-item__decorator {
  border-color: var(--main-color, #1ab394);
  outline: none;
}

.sv-item__control-label {
  position: relative;
  top: 4px;
}

.sv-checkbox__decorator {
  border-radius: 2px;
}

.sv-checkbox__svg {
  border: 3px solid var(--border-color, rgba(64, 64, 64, 0.5));
  border-radius: 2px;
  fill: transparent;
}

.sv-checkbox--allowhover:hover .sv-checkbox__svg {
  border: none;
  background-color: var(--main-hover-color, #9f9f9f);
  fill: white;
}

.sv-checkbox--checked .sv-checkbox__svg {
  border: none;
  background-color: var(--main-color, #1ab394);
  fill: white;
}

.sv-checkbox--checked.sv-checkbox--disabled .sv-checkbox__svg {
  border: none;
  background-color: var(--disable-color, #dbdbdb);
  fill: white;
}

.sv-checkbox--disabled .sv-checkbox__svg {
  border: 3px solid var(--disable-color, #dbdbdb);
}

.sv-radio__decorator {
  border-radius: 100%;
}

.sv-radio__svg {
  border: 3px solid var(--border-color, rgba(64, 64, 64, 0.5));
  border-radius: 100%;
  fill: transparent;
}

.sv-radio--allowhover:hover .sv-radio__svg {
  fill: var(--border-color, rgba(64, 64, 64, 0.5));
}

.sv-radio--checked .sv-radio__svg {
  border-color: var(--radio-checked-color, #404040);
  fill: var(--radio-checked-color, #404040);
}

.sv-radio--disabled .sv-radio__svg {
  border-color: var(--disable-color, #dbdbdb);
}

.sv-radio--disabled.sv-radio--checked .sv-radio__svg {
  fill: var(--disable-color, #dbdbdb);
}

.sv-boolean {
  display: block;
  position: relative;
  line-height: 1.5em;
}

.sv-boolean__switch {
  float: left;
  box-sizing: border-box;
  width: 4em;
  height: 1.5em;
  margin-right: 1.0625em;
  margin-left: 1.3125em;
  padding: 0.125em 0.1875em;
  border-radius: 0.75em;
  margin-bottom: 2px;
}

.sv-boolean__switch {
  background-color: var(--main-color, #1ab394);
}

.sv-boolean__slider {
  background-color: var(--slider-color, #fff);
}

.sv-boolean__label--disabled {
  color: var(--disabled-label-color, rgba(64, 64, 64, 0.5));
}

.sv-boolean--disabled .sv-boolean__switch {
  background-color: var(--main-hover-color, #9f9f9f);
}

.sv-boolean--disabled .sv-boolean__slider {
  background-color: var(--disabled-slider-color, #cfcfcf);
}

.sv-boolean input:focus ~ .sv-boolean__switch {
  outline: 1px solid var(--main-color, #1ab394);
  outline-offset: 1px;
}

[dir=rtl] .sv-boolean__switch,
[style*="direction:rtl"] .sv-boolean__switch,
[style*="direction: rtl"] .sv-boolean__switch {
  float: right;
}

.sv-boolean__slider {
  display: block;
  width: 1.25em;
  height: 1.25em;
  transition-duration: 0.1s;
  transition-property: margin-left;
  transition-timing-function: linear;
  border: none;
  border-radius: 100%;
}

.sv-boolean--indeterminate .sv-boolean__slider {
  margin-left: calc(50% - 0.625em);
}

.sv-boolean--checked .sv-boolean__slider {
  margin-left: calc(100% - 1.25em);
}

.sv-boolean__label {
  cursor: pointer;
  float: left;
}

[dir=rtl] .sv-boolean__label,
[style*="direction:rtl"] .sv-boolean__label,
[style*="direction: rtl"] .sv-boolean__label {
  float: right;
}

[dir=rtl] .sv-boolean--indeterminate .sv-boolean__slider,
[style*="direction:rtl"] .sv-boolean--indeterminate .sv-boolean__slider,
[style*="direction: rtl"] .sv-boolean--indeterminate .sv-boolean__slider {
  margin-right: calc(50% - 0.625em);
}
[dir=rtl] .sv-boolean--checked .sv-boolean__slider,
[style*="direction:rtl"] .sv-boolean--checked .sv-boolean__slider,
[style*="direction: rtl"] .sv-boolean--checked .sv-boolean__slider {
  margin-right: calc(100% - 1.25em);
}

.sv-boolean__switch {
  background-color: var(--main-color, #1ab394);
}

.sv-boolean__slider {
  background-color: var(--slider-color, #fff);
}

.sv-boolean__label--disabled {
  color: var(--disabled-label-color, rgba(64, 64, 64, 0.5));
}

.sv-boolean--disabled .sv-boolean__switch {
  background-color: var(--main-hover-color, #9f9f9f);
}

.sv-boolean--disabled .sv-boolean__slider {
  background-color: var(--disabled-slider-color, #cfcfcf);
}

.sv-imagepicker__item {
  border: none;
  padding: 0.24em;
}

.sv-imagepicker__item--inline {
  display: inline-block;
}

.sv-imagepicker__item--inline:not(:last-child) {
  margin-right: 4%;
}

.sv-imagepicker__image {
  border: 0.24em solid transparent;
  display: block;
  pointer-events: none;
}

.sv-imagepicker__label {
  cursor: inherit;
}

.sv-imagepicker__text {
  font-size: 1.14em;
  padding-left: 0.24em;
}

.sv-imagepicker__item--allowhover:hover .sv-imagepicker__image {
  background-color: var(--main-hover-color, #9f9f9f);
  border-color: var(--main-hover-color, #9f9f9f);
}

.sv-imagepicker__item:not(.sv-imagepicker__item--checked) .sv-imagepicker__control:focus ~ div .sv-imagepicker__image {
  background-color: var(--main-hover-color, #9f9f9f);
  border-color: var(--main-hover-color, #9f9f9f);
}

.sv-imagepicker__item--checked .sv-imagepicker__image {
  background-color: var(--main-color, #1ab394);
  border-color: var(--main-color, #1ab394);
}

.sv-imagepicker__item {
  cursor: pointer;
}

.sv-imagepicker__item--disabled {
  cursor: default;
}

.sv-imagepicker__item--disabled.sv-imagepicker__item--checked .sv-imagepicker__image {
  background-color: var(--disable-color, #dbdbdb);
  border-color: var(--disable-color, #dbdbdb);
}

.sv-dropdown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  background: transparent;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.57em 100%;
  border: none;
  border-bottom: 0.06em solid var(--text-border-color, #d4d4d4);
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  padding-block: 0.25em;
  padding-inline-end: 1.5em;
  padding-inline-start: 0.87em;
  height: 2.19em;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.sv-dropdown input[readonly] {
  pointer-events: none;
}

.sv-dropdown:focus,
.sv-dropdown:focus-within {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231AB394;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E ");
  border-color: var(--text-border-color, #d4d4d4);
  outline: none;
}

.sv-dropdown::-ms-expand {
  display: none;
}

.sv-dropdown--error {
  border-color: var(--error-color, #d52901);
  color: var(--error-color, #d52901);
}

.sv-dropdown--error::placeholder,
.sv-dropdown--error::-ms-input-placeholder {
  color: var(--error-color, #d52901);
}

.sv-dropdown option {
  color: var(--text-color, #404040);
}

.sv-dropdown__value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-size: inherit;
  color: var(--text-color, #404040);
  position: relative;
}
.sv-dropdown__value .sv-string-viewer {
  line-height: 28px;
}

.sv_dropdown_control__input-field-component {
  height: auto;
}

.sv-dropdown__hint-prefix {
  opacity: 0.5;
}
.sv-dropdown__hint-prefix span {
  word-break: unset;
  line-height: 28px;
}

.sv-dropdown__hint-suffix {
  display: flex;
  opacity: 0.5;
}
.sv-dropdown__hint-suffix span {
  word-break: unset;
  line-height: 28px;
}

.sv-dropdown_clean-button {
  padding: 3px 12px;
  margin: auto 0;
}

.sv-dropdown_clean-button-svg {
  width: 12px;
  height: 12px;
}

.sv-input.sv-dropdown:focus-within .sv-dropdown__filter-string-input {
  z-index: 2000;
}

.sv-dropdown__filter-string-input {
  border: none;
  outline: none;
  padding: 0;
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-size: inherit;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  appearance: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.sv-dropdown--empty:not(.sv-input--disabled) .sv-dropdown__filter-string-input::placeholder {
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  color: var(--text-color, #404040);
}

.sv-dropdown__filter-string-input::placeholder {
  color: var(--disabled-text-color, rgba(64, 64, 64, 0.5));
  font-size: inherit;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  appearance: none;
}

[dir=rtl] .sv-dropdown,
[style*="direction:rtl"] .sv-dropdown,
[style*="direction: rtl"] .sv-dropdown {
  background-position: left 0.7em top 50%, 0 0;
}

.sv-input.sv-tagbox:not(.sv-tagbox--empty):not(.sv-input--disabled) {
  height: auto;
  padding: 0.5em;
  padding-inline-end: 2em;
}

.sv-tagbox_clean-button {
  height: 1.5em;
  padding: 0.5em;
  margin: auto 0;
}

.sv-tagbox__value.sv-dropdown__value {
  position: relative;
  gap: 0.25em;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  padding-inline: unset;
  margin-inline: unset;
  margin-block: unset;
}

.sv-tagbox__item {
  position: relative;
  display: flex;
  color: var(--text-color, #404040);
  height: 1.5em;
  padding-block: 0.25em;
  padding-inline-end: 0.4em;
  padding-inline-start: 0.87em;
  border: solid 0.1875em #9f9f9f;
  border-radius: 2px;
  min-width: 2.3125em;
}

.sv-tagbox__item:hover {
  background-color: var(--main-hover-color, #9f9f9f);
  color: var(--body-background-color, white);
}
.sv-tagbox__item:hover .sv-tagbox__item_clean-button-svg use {
  fill: var(--body-background-color, white);
}

.sv-tagbox__item-text {
  color: inherit;
  font-size: 1em;
}

.sv-tagbox__item_clean-button-svg {
  margin: 0.3125em;
  width: 1em;
  height: 1em;
}

.sv-tagbox__item_clean-button-svg use {
  fill: var(--text-color, #404040);
}

.sv-tagbox__filter-string-input {
  width: auto;
  display: flex;
  flex-grow: 1;
  position: initial;
}

.sv-tagbox__placeholder {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  width: auto;
  height: 100%;
  text-align: left;
  cursor: text;
  pointer-events: none;
  color: var(--main-hover-color, #9f9f9f);
}

.sv-tagbox {
  border-bottom: 0.06em solid var(--text-border-color, #d4d4d4);
}

.sv-tagbox:focus {
  border-color: var(--text-border-color, #d4d4d4);
}

.sv-tagbox--error {
  border-color: var(--error-color, #d52901);
  color: var(--error-color, #d52901);
}

.sv-tagbox--error::placeholder {
  color: var(--error-color, #d52901);
}

.sv-tagbox--error::-ms-input-placeholder {
  color: var(--error-color, #d52901);
}

.sv-tagbox .sv-dropdown__filter-string-input {
  height: auto;
}

.sv-text {
  box-sizing: border-box;
  width: 100%;
  height: 2.19em;
  padding: 0.25em 0 0.25em 0.87em;
  border: none;
  border-radius: 0;
  border-bottom: 0.07em solid var(--text-border-color, #d4d4d4);
  box-shadow: none;
  background-color: transparent;
  font-family: inherit;
  font-size: 1em;
}

.sv-text:focus {
  border-color: var(--main-color, #1ab394);
  outline: none;
  box-shadow: none;
}

.sv-text:invalid {
  box-shadow: none;
}

.sv-text:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.sv-text::placeholder {
  opacity: 1;
  color: var(--text-color, #404040);
}

.sv-text:-ms-input-placeholder {
  opacity: 1;
  color: var(--text-color, #404040);
}

.sv-text::-ms-input-placeholder {
  opacity: 1;
  color: var(--text-color, #404040);
}

.sv-text[type=date] {
  padding-right: 2px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.61em top 50%, 0 0;
  background-size: 0.57em auto, 100%;
}

.sv-text[type=date]:focus {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231AB394;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E ");
}

.sv-text[type=date]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: transparent;
}

.sv-text[type=date]::-webkit-clear-button {
  display: none;
}

.sv-text[type=date]::-webkit-inner-spin-button {
  display: none;
}

.sv-text--error {
  color: var(--error-color, #d52901);
  border-color: var(--error-color, #d52901);
}

.sv-text--error::placeholder {
  color: var(--error-color, #d52901);
}

.sv-text--error::-ms-input-placeholder {
  color: var(--error-color, #d52901);
}

input.sv-text,
textarea.sv-comment,
select.sv-dropdown {
  color: var(--text-color, #404040);
  background-color: var(--inputs-background-color, white);
}

.sv-rating {
  color: var(--text-color, #404040);
  padding-bottom: 3px;
}
.sv-rating input:focus + .sv-rating__min-text + .sv-rating__item-text,
.sv-rating input:focus + .sv-rating__item-text {
  outline: 1px solid var(--main-color, #1ab394);
  outline-offset: 2px;
}

.sv-rating__item {
  position: relative;
  display: inline;
}

.sv-rating__item-text {
  min-width: 2.3125em;
  height: 2.3125em;
  display: inline-block;
  color: var(--main-hover-color, #9f9f9f);
  padding: 0 0.3125em;
  border: solid 0.1875em var(--main-hover-color, #9f9f9f);
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.13;
  cursor: pointer;
  margin: 3px 0;
  margin-right: 0.26em;
  box-sizing: border-box;
}
.sv-rating__item-text > span {
  margin-top: 0.44em;
  display: inline-block;
}
.sv-rating__item-text:hover {
  background-color: var(--main-hover-color, #9f9f9f);
  color: var(--body-background-color, white);
}

.sv-rating__item--selected .sv-rating__item-text {
  background-color: var(--main-color, #1ab394);
  color: var(--body-background-color, white);
  border-color: var(--main-color, #1ab394);
}
.sv-rating__item--selected .sv-rating__item-text:hover {
  background-color: var(--main-color, #1ab394);
}

.sv-rating__item-star > svg {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid transparent;
  fill: var(--text-color, #404040);
}
.sv-rating__item-star > svg.sv-star-2 {
  display: none;
}
.sv-rating__item-star > svg:hover {
  border: 1px solid var(--main-hover-color, #9f9f9f);
}

.sv-rating__item-star--selected > svg {
  fill: var(--main-color, #1ab394);
}

.sv-rating__item-smiley > svg {
  height: 24px;
  width: 24px;
  padding: 4px;
  display: inline-block;
  vertical-align: middle;
  border: 3px solid var(--border-color, rgba(64, 64, 64, 0.5));
  margin: 3px 0;
  margin-right: 0.26em;
  fill: var(--main-hover-color, #9f9f9f);
}
.sv-rating__item-smiley > svg > use {
  display: block;
}
.sv-rating__item-smiley > svg:hover {
  border: 3px solid var(--main-hover-color, #9f9f9f);
  background-color: var(--main-hover-color, #9f9f9f);
}

.sv-rating__item-smiley--selected > svg {
  background-color: var(--main-color, #1ab394);
  fill: var(--body-background-color, white);
  border: 3px solid var(--main-color, #1ab394);
}

.sv-rating__min-text {
  font-size: 1em;
  margin-right: 1.25em;
  cursor: pointer;
}

.sv-rating__max-text {
  font-size: 1em;
  margin-left: 0.87em;
  cursor: pointer;
}

.sv-question--disabled .sv-rating__item-text {
  cursor: default;
  color: var(--disable-color, #dbdbdb);
  border-color: var(--disable-color, #dbdbdb);
}
.sv-question--disabled .sv-rating__item-text:hover {
  background-color: transparent;
}
.sv-question--disabled .sv-rating--disabled .sv-rating__item-text:hover .sv-rating__item--selected .sv-rating__item-text {
  background-color: var(--disable-color, #dbdbdb);
  color: var(--body-background-color, white);
}
.sv-question--disabled .sv-rating__item--selected .sv-rating__item-text {
  background-color: var(--disable-color, #dbdbdb);
  color: var(--body-background-color, white);
}
.sv-question--disabled .sv-rating__min-text {
  cursor: default;
}
.sv-question--disabled .sv-rating__max-text {
  cursor: default;
}

.sv-comment {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0.06em solid var(--text-border-color, #d4d4d4);
  border-radius: 0;
  box-sizing: border-box;
  padding: 0.25em 0.87em;
  font-family: inherit;
  font-size: 1em;
  outline: none;
  width: 100%;
  max-width: 100%;
}

.sv-comment:focus {
  border-color: var(--main-color, #1ab394);
}

.sv-file {
  position: relative;
}

.sv-file__decorator {
  background-color: var(--body-container-background-color, #f4f4f4);
  padding: 1.68em 0;
}

.sv-file__clean-btn {
  background-color: var(--remove-button-color, #ff1800);
  margin-top: 1.25em;
}

.sv-file__choose-btn:not(.sv-file__choose-btn--disabled) {
  background-color: var(--add-button-color, #1948b3);
  display: inline-block;
}

.sv-file__choose-btn--disabled {
  cursor: default;
  background-color: var(--disable-color, #dbdbdb);
  display: inline-block;
}

.sv-file__no-file-chosen {
  display: inline-block;
  font-size: 0.87em;
  margin-left: 1em;
}

.sv-file__preview {
  display: inline-block;
  padding-right: 23px;
  position: relative;
  margin-top: 1.25em;
  vertical-align: top;
}

.sv-file__preview:not(:last-child) {
  margin-right: 31px;
}

.sv-file__remove-svg {
  position: absolute;
  fill: #ff1800;
  cursor: pointer;
  height: 16px;
  top: 0;
  right: 0;
  width: 16px;
}
.sv-file__remove-svg .sv-svg-icon {
  width: 16px;
  height: 16px;
}

.sv-file__sign a {
  color: var(--text-color, #404040);
  text-align: left;
  text-decoration: none;
}

.sv-file__wrapper {
  position: relative;
  display: inline-block;
  margin: 0;
  margin-left: 50%;
  transform: translate(-50%, 0);
  padding: 0;
}

.sv-clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.sv-completedpage {
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-size: 1.875em;
  font-weight: bold;
  box-sizing: border-box;
  height: 14em;
  padding-top: 4.5em;
  padding-bottom: 4.5em;
  text-align: center;
  color: var(--text-color, #404040);
  background-color: var(--body-container-background-color, #f4f4f4);
}

.sv-completedpage:before {
  display: block;
  content: "";
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.6, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 72 72' style='enable-background:new 0 0 72 72;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%239A9A9A;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M11.9,72c-0.6-0.1-1.2-0.3-1.8-0.4C4.2,70.1,0,64.7,0,58.6c0-15.1,0-30.1,0-45.2C0,6,6,0,13.4,0 c12,0,24,0,36,0c2.4,0,4.4,1.7,4.6,4c0.2,2.4-1.3,4.4-3.6,4.9C50,9,49.7,9,49.4,9C37.6,9,25.8,9,14,9c-1.5,0-2.8,0.4-3.9,1.5 c-0.8,0.9-1.2,2-1.2,3.2c0,8.2,0,16.4,0,24.6C9,45,9,51.6,9,58.2c0,2.9,1.9,4.8,4.8,4.8c14.9,0,29.7,0,44.6,0c2.6,0,4.6-2,4.6-4.6 c0-5.9,0-11.8,0-17.7c0-2.4,1.6-4.3,3.9-4.6c2.3-0.3,4.3,1,5,3.4c0,0.1,0.1,0.2,0.1,0.2c0,6.8,0,13.6,0,20.4c0,0.1-0.1,0.3-0.1,0.4 c-0.8,5.4-4.7,9.8-10.1,11.2c-0.6,0.1-1.2,0.3-1.8,0.4C44,72,28,72,11.9,72z'/%3E%3Cpath class='st0' d='M35.9,38.8c0.4-0.4,0.5-0.7,0.7-0.9c8.4-8.4,16.8-16.8,25.2-25.2c1.9-1.9,4.5-2,6.3-0.4 c1.9,1.6,2.1,4.6,0.4,6.4c-0.2,0.2-0.3,0.3-0.5,0.5c-9.5,9.5-19.1,19.1-28.6,28.6c-2.2,2.2-4.8,2.2-7,0 c-5.1-5.1-10.2-10.2-15.4-15.4c-1.3-1.3-1.7-2.8-1.2-4.5c0.5-1.7,1.6-2.8,3.4-3.1c1.6-0.4,3.1,0.1,4.2,1.3c4,4,7.9,7.9,11.9,11.9 C35.6,38.2,35.7,38.5,35.9,38.8z'/%3E%3C/g%3E%3C/svg%3E%0A");
  width: 72px;
  height: 72px;
  margin-left: calc(50% - 36px);
  padding: 36px 0px;
  box-sizing: border-box;
}

@media only screen and (min-width: 1000px) {
  .sv-completedpage {
    margin-right: 5%;
    margin-left: calc(5% + 0.293em);
  }
}
@media only screen and (max-width: 1000px) {
  .sv-completedpage {
    margin-left: calc(10px + 0.293em);
    margin-right: 10px;
  }
}

.sv-header {
  white-space: nowrap;
}

.sv-logo--left {
  display: inline-block;
  vertical-align: top;
  margin-right: 2em;
}

.sv-logo--right {
  vertical-align: top;
  margin-left: 2em;
  float: right;
}

.sv-logo--top {
  display: block;
  width: 100%;
  text-align: center;
}

.sv-logo--bottom {
  display: block;
  width: 100%;
  text-align: center;
}

.sv-header__text {
  display: inline-block;
  vertical-align: top;
}

.sjs_sp_container {
  border: 1px dashed var(--disable-color, #dbdbdb);
}

.sjs_sp_placeholder {
  color: var(--foreground-light, var(--sjs-general-forecolor-light, var(--foreground-light, #909090)));
}

:root {
  --sjs-transition-duration: 150ms;
}

.sv-action-bar {
  display: flex;
  box-sizing: content-box;
  position: relative;
  align-items: center;
  margin-left: auto;
  overflow: hidden;
  white-space: nowrap;
}

.sv-action-bar-separator {
  display: inline-block;
  width: 1px;
  height: 24px;
  vertical-align: middle;
  margin-right: 16px;
  background-color: var(--sjs-border-default, var(--border, #d6d6d6));
}

.sv-action-bar--default-size-mode .sv-action-bar-separator {
  margin: 0 var(--sjs-base-unit, var(--base-unit, 8px));
}

.sv-action-bar--small-size-mode .sv-action-bar-separator {
  margin: 0 calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-action-bar-item {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  box-sizing: border-box;
  border: none;
  border-radius: calc(0.5 * (var(--sjs-corner-radius, 4px)));
  background-color: transparent;
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  cursor: pointer;
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  overflow-x: hidden;
  white-space: nowrap;
}

button.sv-action-bar-item {
  overflow: hidden;
}

.sv-action-bar--default-size-mode .sv-action-bar-item {
  height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-size: var(--sjs-font-size, 16px);
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  margin: 0 var(--sjs-base-unit, var(--base-unit, 8px));
}

.sv-action-bar--small-size-mode .sv-action-bar-item {
  height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-size: calc(0.75 * (var(--sjs-font-size, 16px)));
  line-height: var(--sjs-font-size, 16px);
  margin: 0 calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-action:first-of-type .sv-action-bar-item {
  margin-inline-start: 0;
}

.sv-action:last-of-type .sv-action-bar-item {
  margin-inline-end: 0;
}

.sv-action-bar--default-size-mode .sv-action-bar-item__title--with-icon {
  margin-inline-start: var(--sjs-base-unit, var(--base-unit, 8px));
}

.sv-action-bar--small-size-mode .sv-action-bar-item__title--with-icon {
  margin-inline-start: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-action-bar-item__icon svg {
  display: block;
}
.sv-action-bar-item__icon use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.sv-action-bar-item:not(.sv-action-bar-item--pressed):hover:enabled,
.sv-action-bar-item:not(.sv-action-bar-item--pressed):focus:enabled {
  outline: none;
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.sv-action-bar-item--active.sv-action-bar-item--pressed:focus, .sv-action-bar-item--active.sv-action-bar-item--pressed:focus-visible {
  outline: none;
}

.sv-action-bar-item:not(.sv-action-bar-item--pressed):active:enabled {
  opacity: 0.5;
}

.sv-action-bar-item:disabled {
  opacity: 0.25;
  cursor: default;
}

.sv-action-bar-item__title {
  color: inherit;
  vertical-align: middle;
  white-space: nowrap;
}

.sv-action-bar-item--secondary .sv-action-bar-item__icon use {
  fill: var(--sjs-secondary-backcolor, var(--secondary, #ff9814));
}

.sv-action-bar-item--active .sv-action-bar-item__icon use {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.sv-action-bar-item-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  box-sizing: border-box;
  border: none;
  border-radius: calc(0.5 * (var(--sjs-corner-radius, 4px)));
  background-color: transparent;
  cursor: pointer;
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  font-size: var(--sjs-font-size, 16px);
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
}

.sv-expand-action:before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: 10px;
  width: 12px;
  margin: auto 8px;
}

.sv-expand-action--expanded:before {
  transform: rotate(180deg);
}

.sv-dots {
  width: 48px;
}

.sv-dots__item {
  width: 100%;
}
.sv-dots__item .sv-action-bar-item__icon {
  margin: auto;
}

.sv-action--hidden {
  width: 0px;
  height: 0px;
  overflow: hidden;
  visibility: hidden;
}
.sv-action--hidden .sv-action__content {
  min-width: fit-content;
}

.sv-action__content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sv-action__content > * {
  flex: 0 0 auto;
}

.sv-action--space {
  margin-left: auto;
}

.sv-action-bar-item--pressed:not(.sv-action-bar-item--active) {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  opacity: 50%;
}

:root {
  --sjs-transition-duration: 150ms;
}

.sv-dragged-element-shortcut {
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  min-width: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: calc(4.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  cursor: grabbing;
  position: absolute;
  z-index: 10000;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-size: var(--sjs-font-size, 16px);
  padding-left: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
}

.sv-matrixdynamic__drag-icon {
  padding-top: calc(1.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-matrixdynamic__drag-icon:after {
  content: " ";
  display: block;
  height: calc(0.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: calc(1.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  cursor: move;
  margin-top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-matrix-row--drag-drop-ghost-mod td {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.sv-matrix-row--drag-drop-ghost-mod td > * {
  visibility: hidden;
}

:root {
  --sjs-transition-duration: 150ms;
}

sv-popup {
  display: block;
  position: absolute;
}

.sv-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  outline: none;
  z-index: 2000;
  height: 100vh;
}

.sv-dropdown-popup {
  height: 0;
}

.sv-popup__container {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1));
  border-radius: var(--sjs-corner-radius, 4px);
  position: absolute;
  padding: 0;
}

.sv-popup__shadow {
  width: 100%;
  height: 100%;
  border-radius: var(--sjs-corner-radius, 4px);
}

.sv-popup__body-content {
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  border-radius: var(--sjs-corner-radius, 4px);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 100vw;
}

.sv-popup--modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-semitransparent, rgba(144, 144, 144, 0.5));
  padding: calc(11 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(15 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
}

.sv-popup--modal > .sv-popup__container {
  position: static;
  display: flex;
}

.sv-popup--modal > .sv-popup__container > .sv-popup__shadow > .sv-popup__body-content {
  box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1));
  background-color: var(--sjs-general-backcolor-dim-light, var(--background-dim-light, #f9f9f9));
  padding: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: auto;
}
.sv-popup--modal > .sv-popup__container > .sv-popup__shadow > .sv-popup__body-content .sv-popup__body-footer {
  padding-bottom: 2px;
}

.sv-popup--confirm-delete .sv-popup__shadow {
  height: initial;
}
.sv-popup--confirm-delete .sv-popup__container {
  border-radius: var(--sjs-base-unit, var(--base-unit, 8px));
}
.sv-popup--confirm-delete .sv-popup__body-content {
  border-radius: var(--sjs-base-unit, var(--base-unit, 8px));
  max-width: min-content;
  align-items: flex-end;
  min-width: 452px;
}
.sv-popup--confirm-delete .sv-popup__body-header {
  color: var(--sjs-font-editorfont-color, var(--sjs-general-forecolor, rgba(0, 0, 0, 0.91)));
  margin-bottom: 0;
  align-self: self-start;
  /* UI/Default */
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-size: var(--sjs-font-size, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  /* 150% */
}
.sv-popup--confirm-delete .sv-popup__scrolling-content {
  display: none;
}
.sv-popup--confirm-delete .sv-popup__body-footer {
  padding-bottom: 0;
  max-width: max-content;
}
.sv-popup--confirm-delete .sv-popup__body-footer .sv-action-bar {
  gap: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-popup--overlay {
  width: 100%;
  height: var(--sv-popup-overlay-height, 100vh);
}
.sv-popup--overlay .sv-popup__container {
  background: var(--background-semitransparent, rgba(144, 144, 144, 0.5));
  max-width: 100vw;
  max-height: calc(var(--sv-popup-overlay-height, 100vh) - 1 * var(--sjs-base-unit, var(--base-unit, 8px)));
  height: calc(var(--sv-popup-overlay-height, 100vh) - 1 * var(--sjs-base-unit, var(--base-unit, 8px)));
  width: 100%;
  padding-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border: unset;
}
.sv-popup--overlay .sv-popup__body-content {
  max-height: var(--sv-popup-overlay-height, 100vh);
  max-width: 100vw;
  border-radius: calc(4 * (var(--sjs-corner-radius, 4px))) calc(4 * (var(--sjs-corner-radius, 4px))) 0px 0px;
  background: var(--sjs-general-backcolor, var(--background, #fff));
  box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1));
  padding: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(100% - 1 * var(--sjs-base-unit, var(--base-unit, 8px)));
}
.sv-popup--overlay .sv-popup__scrolling-content {
  height: calc(100% - 10 * var(--base-unit, 8px));
}
.sv-popup--overlay .sv-popup__body-footer {
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-popup--overlay .sv-popup__body-footer .sv-action-bar {
  width: 100%;
}
.sv-popup--overlay .sv-popup__body-footer-item {
  width: 100%;
}
.sv-popup--overlay .sv-popup__button.sv-popup__button {
  background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  border: 2px solid var(--sjs-primary-backcolor, var(--primary, #19b394));
  color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
}

.sv-popup--overlay .sv-popup__body-footer .sv-action {
  flex: 1 0 0;
}

.sv-popup--modal .sv-popup__scrolling-content {
  padding: 2px;
  margin: -2px;
}

.sv-popup__scrolling-content {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.sv-popup__scrolling-content::-webkit-scrollbar,
.sv-popup__scrolling-content *::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}
.sv-popup__scrolling-content::-webkit-scrollbar-thumb,
.sv-popup__scrolling-content *::-webkit-scrollbar-thumb {
  background: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
}

.sv-popup__content {
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.sv-popup--show-pointer.sv-popup--top .sv-popup__pointer {
  transform: translate(calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px))))) rotate(180deg);
}

.sv-popup--show-pointer.sv-popup--bottom .sv-popup__pointer {
  transform: translate(calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px)))), calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
}

.sv-popup--show-pointer.sv-popup--right .sv-popup__container {
  transform: translate(var(--sjs-base-unit, var(--base-unit, 8px)));
}
.sv-popup--show-pointer.sv-popup--right .sv-popup__container .sv-popup__pointer {
  transform: translate(-12px, -4px) rotate(-90deg);
}

.sv-popup--show-pointer.sv-popup--left .sv-popup__container {
  transform: translate(calc(-1 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
}
.sv-popup--show-pointer.sv-popup--left .sv-popup__container .sv-popup__pointer {
  transform: translate(-4px, -4px) rotate(90deg);
}

.sv-popup__pointer {
  display: block;
  position: absolute;
}
.sv-popup__pointer:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-left: var(--sjs-base-unit, var(--base-unit, 8px)) solid transparent;
  border-right: var(--sjs-base-unit, var(--base-unit, 8px)) solid transparent;
  border-bottom: var(--sjs-base-unit, var(--base-unit, 8px)) solid var(--sjs-general-backcolor, var(--background, #fff));
  align-self: center;
}

.sv-popup__body-header {
  font-family: Open Sans;
  font-size: calc(1.5 * (var(--sjs-font-size, 16px)));
  line-height: calc(2 * (var(--sjs-font-size, 16px)));
  font-style: normal;
  font-weight: 700;
  margin-bottom: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
}

.sv-popup__body-footer {
  display: flex;
  margin-top: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-popup__body-footer .sv-action-bar {
  gap: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-popup__button {
  margin: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-popup--modal .sv-list__filter,
.sv-popup--overlay .sv-list__filter {
  padding-top: var(--sjs-base-unit, var(--base-unit, 8px));
}
.sv-popup--modal .sv-list__filter-icon,
.sv-popup--overlay .sv-list__filter-icon {
  top: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-popup--dropdown .sv-list__filter {
  margin-bottom: var(--sjs-base-unit, var(--base-unit, 8px));
}
.sv-popup--dropdown .sv-popup__shadow {
  box-shadow: var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1));
}
.sv-popup--dropdown .sv-popup__body-content {
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) 0;
  height: 100%;
}

.sv-popup--dropdown > .sv-popup__container > .sv-popup__shadow > .sv-popup__body-content .sv-list {
  background-color: transparent;
}

.sv-dropdown-popup .sv-popup__body-content {
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
}
.sv-dropdown-popup .sv-list__filter {
  margin-bottom: 0;
}

.sv-popup--overlay .sv-popup__body-content {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.sv-popup--dropdown-overlay {
  z-index: 2001;
  padding: 0;
}
.sv-popup--dropdown-overlay .sv-popup__body-content {
  padding: 0;
  border-radius: 0;
}
.sv-popup--dropdown-overlay .sv-popup__body-footer .sv-action-bar .sv-action {
  flex: 0 0 auto;
}
.sv-popup--dropdown-overlay .sv-popup__button.sv-popup__button {
  background-color: transparent;
  color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  border: none;
  box-shadow: none;
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin: 0;
}
.sv-popup--dropdown-overlay .sv-popup__container {
  max-height: calc(var(--sv-popup-overlay-height, 100vh));
  height: calc(var(--sv-popup-overlay-height, 100vh));
  padding-top: 0;
}
.sv-popup--dropdown-overlay .sv-popup__body-content {
  height: calc(var(--sv-popup-overlay-height, 100vh));
}
.sv-popup--dropdown-overlay .sv-popup__body-footer {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  margin-top: 0;
  padding-top: var(--sjs-base-unit, var(--base-unit, 8px));
  padding-bottom: var(--sjs-base-unit, var(--base-unit, 8px));
  border-top: 1px solid var(--sjs-border-light, var(--border-light, #eaeaea));
}
.sv-popup--dropdown-overlay .sv-popup__scrolling-content {
  height: calc(100% - 6 * var(--base-unit, 8px));
}
.sv-popup--dropdown-overlay .sv-list__filter-icon .sv-svg-icon {
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-popup--dropdown-overlay .sv-list__container {
  padding: 0;
}
.sv-popup--dropdown-overlay .sv-list {
  flex-grow: 1;
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) 0;
}
.sv-popup--dropdown-overlay .sv-list__filter {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-base-unit, var(--base-unit, 8px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-popup--dropdown-overlay .sv-list__filter-icon {
  position: static;
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-popup--dropdown-overlay .sv-list__empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
}
.sv-popup--dropdown-overlay .sv-popup__button:disabled {
  pointer-events: none;
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  opacity: 0.25;
}
.sv-popup--dropdown-overlay .sv-list__filter-clear-button {
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  appearance: none;
  border: none;
  border-radius: 100%;
  background-color: transparent;
}
.sv-popup--dropdown-overlay .sv-list__filter-clear-button svg {
  height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-popup--dropdown-overlay .sv-list__filter-clear-button svg use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}
.sv-popup--dropdown-overlay .sv-list__input {
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  font-size: max(16px, var(--sjs-font-size, 16px));
  line-height: max(24px, 1.5 * (var(--sjs-font-size, 16px)));
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-popup--dropdown-overlay .sv-list__item:hover .sv-list__item-body,
.sv-popup--dropdown-overlay .sv-list__item:focus .sv-list__item-body,
.sv-popup--dropdown-overlay .sv-list__item--focused .sv-list__item-body {
  background: var(--sjs-general-backcolor, var(--background, #fff));
}
.sv-popup--dropdown-overlay .sv-list__item:hover.sv-list__item--selected .sv-list__item-body,
.sv-popup--dropdown-overlay .sv-list__item:focus.sv-list__item--selected .sv-list__item-body,
.sv-popup--dropdown-overlay .sv-list__item--focused.sv-list__item--selected .sv-list__item-body {
  background: var(--sjs-primary-backcolor, var(--primary, #19b394));
  color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
  font-weight: 600;
}
.sv-popup--dropdown-overlay .sv-popup__body-footer .sv-action-bar {
  justify-content: flex-start;
}

.sv-dropdown-popup.sv-popup--dropdown-overlay .sv-list__filter {
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-base-unit, var(--base-unit, 8px)) calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-dropdown-popup.sv-popup--dropdown-overlay .sv-list {
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-dropdown-popup.sv-popup--dropdown-overlay .sv-popup__button.sv-popup__button {
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-dropdown-popup.sv-popup--dropdown-overlay .sv-popup__body-footer {
  padding-top: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-bottom: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: var(--sjs-general-backcolor-dim-light, var(--background-dim-light, #f9f9f9));
}
.sv-dropdown-popup.sv-popup--dropdown-overlay .sv-list__filter-icon .sv-svg-icon {
  width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-dropdown-popup.sv-popup--dropdown-overlay .sv-list__filter-icon {
  height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-dropdown-popup.sv-popup--dropdown-overlay .sv-list__input {
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0 calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-base-unit, var(--base-unit, 8px));
}

.sv-popup--dropdown-overlay.sv-multi-select-list .sv-list__item:hover.sv-list__item--selected .sv-list__item-body,
.sv-popup--dropdown-overlay.sv-multi-select-list .sv-list__item:focus.sv-list__item--selected .sv-list__item-body,
.sv-popup--dropdown-overlay.sv-multi-select-list .sv-list__item--focused.sv-list__item--selected .sv-list__item-body {
  background: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  font-weight: 400;
}

.sv-popup--dropdown-overlay.sv-popup--tablet .sv-popup__body-content {
  --sv-popup-overlay-max-height: calc(var(--sv-popup-overlay-height, 100vh) - var(--sjs-base-unit, var(--base-unit, 8px)) * 8);
  --sv-popup-overlay-max-width: calc(100% - var(--sjs-base-unit, var(--base-unit, 8px)) * 8);
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  max-height: var(--sv-popup-overlay-max-height);
  min-height: min(var(--sv-popup-overlay-max-height), 30 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: auto;
  width: auto;
  min-width: min(40 * (var(--sjs-base-unit, var(--base-unit, 8px))), var(--sv-popup-overlay-max-width));
  max-width: var(--sv-popup-overlay-max-width);
  border-radius: var(--sjs-corner-radius, 4px);
  overflow: hidden;
  margin: 0;
}
.sv-popup--dropdown-overlay.sv-popup--tablet .sv-popup__content,
.sv-popup--dropdown-overlay.sv-popup--tablet .sv-popup__scrolling-content,
.sv-popup--dropdown-overlay.sv-popup--tablet .sv-list__container {
  flex-grow: 1;
}

.sv-popup--visible {
  opacity: 1;
}

.sv-popup--hidden {
  opacity: 0;
}

.sv-popup--animate-enter {
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 0.15s;
}

.sv-popup--modal.sv-popup--animate-enter {
  animation-duration: 0.25s;
}

.sv-popup--animate-leave {
  animation-direction: reverse;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 0.15s;
}

.sv-popup--modal.sv-popup--animate-leave {
  animation-duration: 0.25s;
}

.sv-popup--hidden {
  opacity: 0;
}

@keyframes modalMoveDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(64px);
  }
}
@keyframes modalMoveUp {
  from {
    transform: translateY(64px);
  }
  to {
    transform: translateY(0);
  }
}
.sv-popup--modal.sv-popup--animate-leave .sv-popup__container {
  animation-name: modalMoveDown;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
}

.sv-popup--modal.sv-popup--animate-enter .sv-popup__container {
  animation-name: modalMoveUp;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
}

:root {
  --sjs-transition-duration: 150ms;
}

.sv-button-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: var(--sjs-font-size, 16px);
  overflow: auto;
  border: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
}

.sv-button-group:focus-within {
  box-shadow: 0 0 0 1px var(--sjs-primary-backcolor, var(--primary, #19b394));
  border-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.sv-button-group__item {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 100%;
  padding: 11px calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  outline: none;
  font-size: var(--sjs-font-size, 16px);
  font-weight: 400;
  background: var(--sjs-general-backcolor, var(--background, #fff));
  cursor: pointer;
  overflow: hidden;
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  position: relative;
}
.sv-button-group__item:not(:last-of-type) {
  border-right: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
}

.sv-button-group__item--hover:hover {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.sv-button-group__item-icon {
  display: block;
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-button-group__item-icon use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.sv-button-group__item--selected {
  font-weight: 600;
  color: var(--sjs-primary-backcolor, var(--primary, #19b394));
}
.sv-button-group__item--selected .sv-button-group__item-icon use {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}
.sv-button-group__item--selected:hover {
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
}

.sv-button-group__item-decorator {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.sv-button-group__item-caption {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sv-button-group__item-icon + .sv-button-group__item-caption {
  margin-left: var(--sjs-base-unit, var(--base-unit, 8px));
}

.sv-button-group__item--disabled {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  cursor: default;
}
.sv-button-group__item--disabled .sv-button-group__item-decorator {
  opacity: 0.25;
  font-weight: normal;
}
.sv-button-group__item--disabled .sv-button-group__item-icon use {
  fill: var(--sjs-general-forecolor, var(--foreground, #161616));
}
.sv-button-group__item--disabled:hover {
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
}

.sv-button-group:focus-within {
  box-shadow: 0 0 0 1px var(--sjs-primary-backcolor, var(--primary, #19b394));
  border-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.sv-visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.sv-hidden {
  display: none !important;
}

.sv-title-actions {
  display: flex;
  align-items: center;
  width: 100%;
}

.sv-title-actions__title {
  flex-wrap: wrap;
  max-width: 90%;
  min-width: 50%;
  white-space: initial;
}

.sv-action-title-bar {
  min-width: 56px;
}

.sv-title-actions .sv-title-actions__title {
  flex-wrap: wrap;
  flex: 0 1 auto;
  max-width: unset;
  min-width: unset;
}
.sv-title-actions .sv-action-title-bar {
  flex: 1 1 auto;
  justify-content: flex-end;
  min-width: unset;
}

:root {
  --sjs-transition-duration: 150ms;
}

:root {
  --sjs-transition-duration: 150ms;
}

.sv_window {
  position: fixed;
  bottom: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  right: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: var(--sjs-base-unit, var(--base-unit, 8px));
  border: 1px solid var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
  box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1)), var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1));
  background-clip: padding-box;
  z-index: 100;
  max-height: 50vh;
  overflow: auto;
  box-sizing: border-box;
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  width: calc(100% - 4 * (var(--sjs-base-unit, var(--base-unit, 8px)))) !important;
}
@-moz-document url-prefix() {
  .sv_window,
  .sv_window * {
    scrollbar-width: thin;
    scrollbar-color: var(--sjs-border-default, var(--border, #d6d6d6)) transparent;
  }
}
.sv_window::-webkit-scrollbar,
.sv_window *::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: transparent;
}
.sv_window::-webkit-scrollbar-thumb,
.sv_window *::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 32px;
  background-color: var(--sjs-border-default, var(--border, #d6d6d6));
}
.sv_window::-webkit-scrollbar-track,
.sv_window *::-webkit-scrollbar-track {
  background: transparent;
}
.sv_window::-webkit-scrollbar-thumb:hover,
.sv_window *::-webkit-scrollbar-thumb:hover {
  border: 2px solid rgba(0, 0, 0, 0);
  background-color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.sv_window_root-content {
  height: 100%;
}

.sv_window--full-screen {
  top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  left: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  right: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  bottom: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  max-height: 100%;
  width: initial !important;
  max-width: initial !important;
}

.sv_window_header {
  display: flex;
  justify-content: flex-end;
}

.sv_window_content {
  overflow: hidden;
}

.sv_window--collapsed {
  height: initial;
}
.sv_window--collapsed .sv_window_header {
  height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) var(--sjs-base-unit, var(--base-unit, 8px)) var(--sjs-base-unit, var(--base-unit, 8px)) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  border-radius: var(--sjs-base-unit, var(--base-unit, 8px));
  display: flex;
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}
.sv_window--collapsed .sv_window_content {
  display: none;
}
.sv_window--collapsed .sv_window_buttons_container {
  margin-top: 0;
  margin-right: 0;
}

.sv_window_header_title_collapsed {
  color: var(--sjs-general-dim-forecolor, rgba(0, 0, 0, 0.91));
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-style: normal;
  font-weight: 600;
  font-size: var(--sjs-font-size, 16px);
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sv_window_header_description {
  color: var(--sjs-font-questiondescription-color, var(--sjs-general-forecolor-light, rgba(0, 0, 0, 0.45)));
  font-feature-settings: "salt" on;
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-style: normal;
  font-size: var(--sjs-font-size, 16px);
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sv_window_buttons_container {
  position: fixed;
  margin-top: var(--sjs-base-unit, var(--base-unit, 8px));
  margin-right: var(--sjs-base-unit, var(--base-unit, 8px));
  display: flex;
  gap: var(--sjs-base-unit, var(--base-unit, 8px));
  z-index: 10000;
}

.sv_window_button {
  display: flex;
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
  justify-content: center;
  align-items: center;
  border-radius: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  cursor: pointer;
}
.sv_window_button:hover, .sv_window_button:active {
  background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
}
.sv_window_button:hover svg use,
.sv_window_button:hover svg path, .sv_window_button:active svg use,
.sv_window_button:active svg path {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}
.sv_window_button:active {
  opacity: 0.5;
}
.sv_window_button svg use,
.sv_window_button svg path {
  fill: var(--sjs-general-dim-forecolor-light, rgba(0, 0, 0, 0.45));
}

sv-brand-info, .sv-brand-info {
  z-index: 1;
  position: relative;
  margin-top: 1px;
}

.sv-brand-info {
  width: 100%;
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  text-align: center;
  color: #161616;
  background: white;
  padding: 32px 0;
  box-shadow: 0px -1px 0px #D6D6D6;
}
.sv-brand-info a {
  color: #161616;
  text-decoration-line: underline;
}

.sv-brand-info__text {
  font-weight: 600;
  font-size: var(--sjs-font-size, 16px);
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  color: #161616;
}

.sv-brand-info__logo {
  display: inline-block;
}
.sv-brand-info__logo img {
  width: 118px;
}

.sv-brand-info__terms {
  font-weight: 400;
  font-size: calc(0.75 * (var(--sjs-font-size, 16px)));
  line-height: var(--sjs-font-size, 16px);
}
.sv-brand-info__terms a {
  color: #909090;
}

:root {
  --sjs-transition-duration: 150ms;
}

:root {
  --sjs-transition-duration: 150ms;
}

.sv-ranking {
  outline: none;
  user-select: none;
  -webkit-user-select: none;
}

.sv-ranking-item {
  cursor: pointer;
  position: relative;
  height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  opacity: 1;
}

.sv-ranking-item:focus .sv-ranking-item__icon--hover {
  visibility: hidden;
}

.sv-ranking-item:hover:not(:focus) .sv-ranking-item__icon--hover {
  visibility: visible;
}

.sv-question--disabled .sv-ranking-item:hover .sv-ranking-item__icon--hover {
  visibility: hidden;
}

.sv-ranking-item:focus {
  outline: none;
}

.sv-ranking-item:focus .sv-ranking-item__icon--focus {
  visibility: visible;
  top: calc(0.6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-ranking-item:focus .sv-ranking-item__index {
  background: var(--sjs-general-backcolor, var(--background, #fff));
  outline: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.sv-ranking-item__content.sv-ranking-item__content {
  display: flex;
  align-items: center;
  line-height: 1em;
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0px;
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-ranking-item__icon-container {
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  flex-shrink: 0;
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-ranking-item--disabled.sv-ranking-item--disabled,
.sv-ranking-item--readonly.sv-ranking-item--readonly,
.sv-ranking-item--preview.sv-ranking-item--preview {
  cursor: initial;
  user-select: initial;
  -webkit-user-select: initial;
}
.sv-ranking-item--disabled.sv-ranking-item--disabled .sv-ranking-item__icon-container.sv-ranking-item__icon-container .sv-ranking-item__icon.sv-ranking-item__icon,
.sv-ranking-item--readonly.sv-ranking-item--readonly .sv-ranking-item__icon-container.sv-ranking-item__icon-container .sv-ranking-item__icon.sv-ranking-item__icon,
.sv-ranking-item--preview.sv-ranking-item--preview .sv-ranking-item__icon-container.sv-ranking-item__icon-container .sv-ranking-item__icon.sv-ranking-item__icon {
  visibility: hidden;
}

.sv-ranking-item__icon.sv-ranking-item__icon {
  visibility: hidden;
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
  position: absolute;
  top: var(--sjs-base-unit, var(--base-unit, 8px));
  width: calc(1.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-ranking-item__index.sv-ranking-item__index {
  --sjs-internal-font-editorfont-size: var(--sjs-mobile-font-editorfont-size, var(--sjs-font-editorfont-size, var(--sjs-font-size, 16px)));
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  color: var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)));
  font-size: var(--sjs-internal-font-editorfont-size);
  border-radius: 100%;
  border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid transparent;
  width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  box-sizing: border-box;
  font-weight: 600;
  margin-left: calc(0 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  transition: outline var(--sjs-transition-duration, 150ms), background var(--sjs-transition-duration, 150ms);
  outline: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid transparent;
}
.sv-ranking-item__index.sv-ranking-item__index svg {
  fill: var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)));
  width: var(--sjs-internal-font-editorfont-size);
  height: var(--sjs-internal-font-editorfont-size);
}

.sv-ranking-item__text {
  --sjs-internal-font-editorfont-size: var(--sjs-mobile-font-editorfont-size, var(--sjs-font-editorfont-size, var(--sjs-font-size, 16px)));
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)));
  font-size: var(--sjs-internal-font-editorfont-size);
  line-height: calc(1.5 * (var(--sjs-internal-font-editorfont-size)));
  margin: 0 calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sd-ranking--disabled .sv-ranking-item__text {
  color: var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)));
  opacity: 0.25;
}

.sv-ranking-item--disabled .sv-ranking-item__text {
  color: var(--sjs-font-questiondescription-color, var(--sjs-general-forecolor-light, rgba(0, 0, 0, 0.45)));
  opacity: 0.25;
}

.sv-ranking-item--readonly .sv-ranking-item__index {
  background-color: var(--sjs-questionpanel-hovercolor, var(--sjs-general-backcolor-dark, rgb(248, 248, 248)));
}

.sv-ranking-item--preview .sv-ranking-item__index {
  background-color: transparent;
  border: 1px solid var(--sjs-general-forecolor, var(--foreground, #161616));
  box-sizing: border-box;
}

.sv-ranking-item__ghost.sv-ranking-item__ghost {
  display: none;
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: calc(31 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  z-index: 1;
  position: absolute;
  left: 0;
  top: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

[dir=rtl] .sv-ranking-item__ghost {
  left: initilal;
  right: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-ranking-item--ghost .sv-ranking-item__ghost {
  display: block;
}

.sv-ranking-item--ghost .sv-ranking-item__content {
  visibility: hidden;
}

.sv-ranking-item--drag .sv-ranking-item__content {
  box-shadow: var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1));
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-ranking--drag .sv-ranking-item:hover .sv-ranking-item__icon {
  visibility: hidden;
}

.sv-ranking-item--drag .sv-ranking-item__icon--hover {
  visibility: visible;
}

.sv-ranking--mobile .sv-ranking-item__icon--hover {
  visibility: visible;
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.sv-ranking--mobile.sv-ranking--drag .sv-ranking-item--ghost .sv-ranking-item__icon.sv-ranking-item__icon--hover {
  visibility: hidden;
}

.sv-ranking--mobile.sv-ranking-shortcut {
  max-width: 80%;
}

.sv-ranking--mobile .sv-ranking-item__index.sv-ranking-item__index {
  margin-left: 0;
}
.sv-ranking--mobile .sd-element--with-frame .sv-ranking-item__icon {
  margin-left: 0;
}

.sv-ranking--design-mode .sv-ranking-item:hover .sv-ranking-item__icon {
  visibility: hidden;
}

.sv-ranking--disabled {
  opacity: 0.8;
}

.sv-ranking-shortcut[hidden] {
  display: none;
}

.sv-ranking-shortcut .sv-ranking-item__icon {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.sv-ranking-shortcut .sv-ranking-item__text {
  margin-right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-ranking-shortcut .sv-ranking-item__icon--hover {
  visibility: visible;
}

.sv-ranking-shortcut .sv-ranking-item__icon {
  width: calc(1.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  top: var(--sjs-base-unit, var(--base-unit, 8px));
}

.sv-ranking-shortcut .sv-ranking-item__content {
  padding-left: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-ranking-shortcut .sv-ranking-item__icon-container {
  margin-left: var(--sjs-base-unit, var(--base-unit, 8px));
}

.sv-ranking--select-to-rank {
  display: flex;
}

.sv-ranking--select-to-rank-vertical {
  flex-direction: column-reverse;
}
.sv-ranking--select-to-rank-vertical .sv-ranking__containers-divider {
  margin: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) 0;
  height: 1px;
}
.sv-ranking--select-to-rank-vertical .sv-ranking__container--empty {
  padding-top: var(--sjs-base-unit, var(--base-unit, 8px));
  padding-bottom: var(--sjs-base-unit, var(--base-unit, 8px));
  display: flex;
  justify-content: center;
  align-items: center;
}

.sv-ranking-item--animate-item-removing {
  --animation-height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  animation-name: moveIn, fadeIn;
  animation-direction: reverse;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-duration: var(--sjs-ranking-move-out-duration, 150ms), var(--sjs-ranking-fade-out-duration, 100ms);
  animation-delay: var(--sjs-ranking-move-out-delay, 0ms), 0s;
}

.sv-ranking-item--animate-item-adding {
  --animation-height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  animation-name: moveIn, fadeIn;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-duration: var(--sjs-ranking-move-in-duration, 150ms), var(--sjs-ranking-fade-in-duration, 100ms);
  animation-delay: 0s, var(--sjs-ranking-fade-in-delay, 150ms);
}

.sv-ranking-item--animate-item-adding-empty {
  --animation-height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  animation-name: fadeIn;
  opacity: 0;
  animation-timing-function: linear;
  animation-duration: var(--sjs-ranking-fade-in-duration, 100ms);
  animation-delay: 0;
}

.sv-ranking-item--animate-item-removing-empty {
  --animation-height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  animation-name: fadeIn;
  animation-direction: reverse;
  animation-timing-function: linear;
  animation-duration: var(--sjs-ranking-fade-out-duration, 100ms);
  animation-delay: 0;
}

@keyframes sv-animate-item-opacity-reverse-keyframes {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes sv-animate-item-opacity-keyframes {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.sv-ranking--select-to-rank-horizontal .sv-ranking__container {
  max-width: calc(50% - 1px);
}
.sv-ranking--select-to-rank-horizontal .sv-ranking__containers-divider {
  width: 1px;
}
.sv-ranking--select-to-rank-horizontal .sv-ranking__container--to .sv-ranking-item {
  left: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-ranking--select-to-rank-horizontal .sv-ranking__container--empty.sv-ranking__container--to .sv-ranking-item {
  left: initial;
}
.sv-ranking--select-to-rank-horizontal .sv-ranking__container--empty.sv-ranking__container--to .sv-ranking__container-placeholder {
  padding-left: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-ranking--select-to-rank-horizontal .sv-ranking__container--empty.sv-ranking__container--from .sv-ranking__container-placeholder {
  padding-right: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-ranking__container-placeholder {
  color: var(--sjs-font-questiondescription-color, var(--sjs-general-dim-forecolor-light, rgba(0, 0, 0, 0.45)));
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-style: normal;
  font-size: var(--sjs-font-size, 16px);
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  white-space: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-bottom: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
}

.sv-ranking__container {
  flex: 1;
}

.sv-ranking__container--empty {
  box-sizing: border-box;
  text-align: center;
}

.sv-ranking__containers-divider {
  background: var(--sjs-border-default, var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16))));
}

.sv-ranking__container--from .sv-ranking-item__icon--focus {
  display: none;
}

.sv-ranking--select-to-rank-horizontal .sv-ranking__container--to .sv-ranking-item {
  left: 0 !important;
  padding-left: 16px;
}
.sv-ranking--select-to-rank-horizontal .sv-ranking__container--to .sv-ranking-item .sv-ranking-item__ghost {
  left: initial;
}

:root {
  --sjs-transition-duration: 150ms;
}

.sv-list {
  padding: 0;
  margin: 0;
  overflow-y: auto;
  background: var(--sjs-general-backcolor, var(--background, #fff));
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  list-style-type: none;
}

.sv-list__empty-container {
  width: 100%;
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  box-sizing: border-box;
  padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-list__empty-text {
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  font-size: var(--sjs-font-size, 16px);
  font-weight: 400;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.sv-list__item {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sv-list__item-body {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding-block: var(--sjs-base-unit, var(--base-unit, 8px));
  padding-inline-end: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-inline-start: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)));
  font-weight: normal;
  font-size: var(--sjs-font-size, 16px);
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  cursor: pointer;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: background-color var(--sjs-transition-duration, 150ms), color var(--sjs-transition-duration, 150ms);
}

.sv-list__item.sv-list__item--focused:not(.sv-list__item--selected) {
  outline: none;
}
.sv-list__item.sv-list__item--focused:not(.sv-list__item--selected) .sv-list__item-body {
  border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid var(--sjs-border-light, var(--border-light, #eaeaea));
  border-radius: var(--sjs-corner-radius, 4px);
  padding-block: calc(0.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-inline-end: calc(7.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-inline-start: calc(1.75 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-list__item.sv-list__item--focused:not(.sv-list__item--selected) .sv-string-viewer {
  margin-inline-start: calc(-0.25 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-list__item:hover,
.sv-list__item:focus {
  outline: none;
}
.sv-list__item:hover .sv-list__item-body,
.sv-list__item:focus .sv-list__item-body {
  background-color: var(--sjs-questionpanel-hovercolor, var(--sjs-general-backcolor-dark, rgb(248, 248, 248)));
}

.sv-list__item--with-icon.sv-list__item--with-icon {
  padding: 0;
}
.sv-list__item--with-icon.sv-list__item--with-icon .sv-list__item-body {
  padding-top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-bottom: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  gap: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  display: flex;
}

.sv-list__item-icon {
  float: left;
  flex-shrink: 0;
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-list__item-icon svg {
  display: block;
}
.sv-list__item-icon use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

[dir=rtl] .sv-list__item-icon,
[style*="direction:rtl"] .sv-list__item-icon,
[style*="direction: rtl"] .sv-list__item-icon {
  float: right;
}

.sv-list__item-separator {
  margin: var(--sjs-base-unit, var(--base-unit, 8px)) 0;
  height: 1px;
  background-color: var(--sjs-border-default, var(--border, #d6d6d6));
}

.sv-list--filtering .sv-list__item-separator {
  display: none;
}

.sv-list__item.sv-list__item--selected .sv-list__item-body,
.sv-list__item.sv-list__item--selected:hover .sv-list__item-body,
.sv-list__item.sv-list__item--selected.sv-list__item--focused .sv-list__item-body,
.sv-multi-select-list .sv-list__item.sv-list__item--selected.sv-list__item--focused .sv-list__item-body,
li:focus .sv-list__item.sv-list__item--selected .sv-list__item-body {
  background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
  font-weight: 600;
}
.sv-list__item.sv-list__item--selected .sv-list__item-icon use,
.sv-list__item.sv-list__item--selected:hover .sv-list__item-icon use,
.sv-list__item.sv-list__item--selected.sv-list__item--focused .sv-list__item-icon use,
.sv-multi-select-list .sv-list__item.sv-list__item--selected.sv-list__item--focused .sv-list__item-icon use,
li:focus .sv-list__item.sv-list__item--selected .sv-list__item-icon use {
  fill: var(--sjs-general-backcolor, var(--background, #fff));
}

.sv-multi-select-list .sv-list__item.sv-list__item--selected .sv-list__item-body,
.sv-multi-select-list .sv-list__item.sv-list__item--selected:hover .sv-list__item-body {
  background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  color: var(--sjs-font-questiontitle-color, var(--sjs-general-forecolor, var(--foreground, #161616)));
  font-weight: 400;
}

.sv-list__item.sv-list__item--disabled .sv-list__item-body {
  cursor: default;
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.sv-list__item span {
  white-space: nowrap;
}

.sv-list__item-text--wrap span {
  white-space: normal;
  word-wrap: break-word;
}

.sv-list__container {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  display: flex;
  min-height: 0;
}

.sv-list__filter {
  border-bottom: 1px solid var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
  background: var(--sjs-general-backcolor, var(--background, #fff));
  padding-bottom: var(--sjs-base-unit, var(--base-unit, 8px));
}

.sv-list__filter-icon {
  display: block;
  position: absolute;
  top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  inset-inline-start: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-list__filter-icon .sv-svg-icon {
  width: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.sv-list__filter-icon .sv-svg-icon use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.sv-list__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  background: var(--sjs-general-backcolor, var(--background, #fff));
  box-sizing: border-box;
  width: 100%;
  min-width: calc(30 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  outline: none;
  font-size: var(--sjs-font-size, 16px);
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-inline-start: calc(7 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  border: none;
}

.sv-list__input::placeholder {
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.sv-list__input:disabled,
.sv-list__input:disabled::placeholder {
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.sv-list__loading-indicator {
  pointer-events: none;
}

.sv-list__loading-indicator .sv-list__item-body {
  background-color: transparent;
}

:root {
  --sjs-transition-duration: 150ms;
}

.sv-save-data_root {
  position: fixed;
  left: 50%;
  bottom: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background: var(--sjs-general-backcolor, var(--background, #fff));
  opacity: 0;
  padding: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-shadow: var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1));
  border-radius: calc(2 * (var(--sjs-corner-radius, 4px)));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  min-width: calc(30 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  text-align: center;
  z-index: 1600;
  font-family: var(--sjs-font-family, var(--font-family, var(--sjs-default-font-family)));
  font-size: var(--sjs-font-size, 16px);
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: translateX(-50%) translateY(calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  transition-timing-function: ease-in;
  transition-property: transform, opacity;
  transition-delay: 0.25s;
  transition: 0.5s;
}

.sv-save-data_root.sv-save-data_root--shown {
  transition-timing-function: ease-out;
  transition-property: transform, opacity;
  transform: translateX(-50%) translateY(0);
  transition-delay: 0.25s;
  opacity: 0.75;
}

.sv-save-data_root span {
  display: flex;
  flex-grow: 1;
}
.sv-save-data_root .sv-action-bar {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
}

.sv-save-data_root--shown.sv-save-data_success,
.sv-save-data_root--shown.sv-save-data_error {
  opacity: 1;
}

.sv-save-data_root.sv-save-data_root--with-buttons {
  padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-save-data_root.sv-save-data_error {
  background-color: var(--sjs-special-red, var(--red, #e60a3e));
  color: var(--sjs-general-backcolor, var(--background, #fff));
  font-weight: 600;
  gap: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.sv-save-data_root.sv-save-data_error .sv-save-data_button {
  font-weight: 600;
  font-size: var(--sjs-font-size, 16px);
  line-height: calc(1.5 * (var(--sjs-font-size, 16px)));
  height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: #ffffff;
  background-color: var(--sjs-special-red, var(--red, #e60a3e));
  border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid #ffffff;
  border-radius: calc(1.5 * (var(--sjs-corner-radius, 4px)));
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  display: flex;
  align-items: center;
}

.sv-save-data_root.sv-save-data_error .sv-save-data_button:hover,
.sv-save-data_root.sv-save-data_error .sv-save-data_button:focus {
  color: var(--sjs-special-red, var(--red, #e60a3e));
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
}

.sv-save-data_root.sv-save-data_success {
  background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
  color: #ffffff;
  font-weight: 600;
}

.sv-string-viewer.sv-string-viewer--multiline {
  white-space: pre-wrap;
}

.sjs_sp_container {
  position: relative;
  max-width: 100%;
}

.sjs_sp_controls {
  position: absolute;
  left: 0;
  bottom: 0;
}

.sjs_sp_controls > button {
  user-select: none;
}

.sjs_sp_container > div > canvas:focus {
  outline: none;
}

.sjs_sp_placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.sjs_sp_canvas {
  position: relative;
  max-width: 100%;
  display: block;
}

.sjs_sp__background-image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

:root {
  --sjs-default-font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sv-boolean__decorator {
  border-radius: 2px;
}

.sv_main .sv-boolean__decorator + .sv-boolean__label {
  float: none;
  vertical-align: top;
  margin-left: 0.5em;
}

.sv-boolean__svg {
  border: none;
  border-radius: 2px;
  background-color: #1ab394;
  fill: white;
  width: 24px;
  height: 24px;
}

.sv-boolean--allowhover:hover .sv-boolean__checked-path {
  display: inline-block;
}
.sv-boolean--allowhover:hover .sv-boolean__svg {
  background-color: #9f9f9f;
  fill: white;
}
.sv-boolean--allowhover:hover .sv-boolean__unchecked-path,
.sv-boolean--allowhover:hover .sv-boolean__indeterminate-path {
  display: none;
}

.sv-boolean__checked-path,
.sv-boolean__indeterminate-path {
  display: none;
}

.sv-boolean--indeterminate .sv-boolean__svg {
  background-color: inherit;
  fill: #1ab394;
}
.sv-boolean--indeterminate .sv-boolean__indeterminate-path {
  display: inline-block;
}

.sv-boolean--indeterminate .sv-boolean__unchecked-path,
.sv-boolean--checked .sv-boolean__unchecked-path {
  display: none;
}

.sv-boolean--checked .sv-boolean__checked-path {
  display: inline-block;
}

.sv-boolean--disabled.sv-boolean--indeterminate .sv-boolean__svg {
  background-color: inherit;
  fill: #dbdbdb;
}

.sv-boolean--disabled .sv-boolean__svg {
  background-color: #dbdbdb;
}

td.sv_matrix_cell .sv_qbln,
td.td.sv_matrix_cell .sv_qbln {
  text-align: center;
}
td.sv_matrix_cell .sv_qbln .sv-boolean,
td.td.sv_matrix_cell .sv_qbln .sv-boolean {
  text-align: initial;
}

sv-components-container,
.sd-components-container {
  display: flex;
}

.sv-components-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.sv-components-column {
  display: flex;
  flex-direction: column;
}

.sv-components-column--expandable {
  flex-grow: 1;
}

.sv-components-row > .sv-components-column--expandable {
  width: 1px;
}

.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question {
  display: block;
  width: 100% !important;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question .sv-question__header--location--left,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question .sv-question__header--location--left {
  float: none;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question .sv-selectbase__item--inline,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question .sv-imagepicker__item--inline,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question .sv-selectbase__item--inline,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question .sv-imagepicker__item--inline {
  display: block;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table {
  display: block;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table thead,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table thead {
  display: none;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table td.sv-table__cell--choice,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table td.sv-table__cell--choice {
  text-align: initial;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table tbody,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table tr,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table td,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table tbody,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table tr,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table td {
  display: block;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrixdropdown td:before,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrixdynamic td:before,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrixdropdown td:before,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrixdynamic td:before {
  content: attr(data-responsive-title);
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrixdropdown .sv-table__cell--row-text:before,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrixdynamic .sv-table__cell--row-text:before,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrixdropdown .sv-table__cell--row-text:before,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrixdynamic .sv-table__cell--row-text:before {
  content: none;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrix-root td label.sv-matrix__label,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrix-root td label.sv-matrix__label {
  display: inline;
}
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrix-root .sv-matrix__cell,
.sv_m600 .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrix-root .sv-matrix__cell {
  text-align: initial;
}

@media (max-width: 600px) {
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question {
    display: block;
    width: 100% !important;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question .sv-question__header--location--left,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question .sv-question__header--location--left {
    float: none;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question .sv-selectbase__item--inline,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question .sv-imagepicker__item--inline,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question .sv-selectbase__item--inline,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question .sv-imagepicker__item--inline {
    display: block;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table {
    display: block;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table thead,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table thead {
    display: none;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table td.sv-table__cell--choice,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table td.sv-table__cell--choice {
    text-align: initial;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table tbody,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table tr,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-table td,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table tbody,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table tr,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-table td {
    display: block;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrixdropdown td:before,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrixdynamic td:before,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrixdropdown td:before,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrixdynamic td:before {
    content: attr(data-responsive-title);
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrixdropdown .sv-table__cell--row-text:before,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrixdynamic .sv-table__cell--row-text:before,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrixdropdown .sv-table__cell--row-text:before,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrixdynamic .sv-table__cell--row-text:before {
    content: none;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrix-root td label.sv-matrix__label,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrix-root td label.sv-matrix__label {
    display: inline;
  }
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-question table.sv-matrix-root .sv-matrix__cell,
  .sv-root-modern .sv-container-modern .sv-body .sv-page .sv-row .sv-row__question table.sv-matrix-root .sv-matrix__cell {
    text-align: initial;
  }
}
body {
  --sv-modern-mark: true;
}

.sv-matrixdynamic__drag-icon {
  padding-top: 16px;
}

.sv-matrixdynamic__drag-icon:after {
  content: " ";
  display: block;
  height: 6px;
  width: 20px;
  border: 1px solid var(--border-color, rgba(64, 64, 64, 0.5));
  box-sizing: border-box;
  border-radius: 10px;
  cursor: move;
  margin-top: 12px;
}

.sv-matrix__drag-drop-ghost-position-top,
.sv-matrix__drag-drop-ghost-position-bottom {
  position: relative;
}

.sv-matrix__drag-drop-ghost-position-top::after,
.sv-matrix__drag-drop-ghost-position-bottom::after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: var(--main-color, #1ab394);
  position: absolute;
  left: 0;
}

.sv-matrix__drag-drop-ghost-position-top::after {
  top: 0;
}

.sv-matrix__drag-drop-ghost-position-bottom::after {
  bottom: 0;
}

.sv-skeleton-element {
  background-color: var(--background-dim, var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3)));
}